import { makeAutoObservable } from 'mobx'
import { ServerStore, AuthStore, UserStore, NotificationStore, ListingStore } from './'
import { AccountStore } from './AccountStore'

export class ApplicationStore {
  public static names = {
    serverStore: 'serverStore',
    authStore: 'authStore',
    userStore: 'userStore',
    notificationStore: 'notificationStore',
    listingStore: 'listingStore',
    accountStore: 'accountStore',
  }
  public readonly serverStore
  public readonly authStore
  public readonly userStore
  public readonly notificationStore
  public readonly listingStore
  public readonly accountStore: AccountStore

  public sidebarShow = true
  public sidebarUnfoldable = false
  public siteSettings: { [key: string]: any } | undefined = undefined

  constructor() {
    makeAutoObservable(this)
    this.authStore = new AuthStore()
    this.notificationStore = new NotificationStore()
    this.listingStore = new ListingStore(this.authStore, this.notificationStore)
    this.serverStore = new ServerStore(this.authStore, this.notificationStore)
    this.userStore = new UserStore(this.authStore, this.notificationStore)
    this.accountStore = new AccountStore(this.authStore, this.notificationStore)
  }

  getStores = () => {
    return {
      [ApplicationStore.names.serverStore]: this.serverStore,
      [ApplicationStore.names.authStore]: this.authStore,
      [ApplicationStore.names.userStore]: this.userStore,
      [ApplicationStore.names.notificationStore]: this.notificationStore,
      [ApplicationStore.names.listingStore]: this.listingStore,
      [ApplicationStore.names.accountStore]: this.accountStore,
    }
  }

  public toggleSidebarShow() {
    this.setSidebarShow(!this.sidebarShow)
  }

  public setSidebarShow(val: boolean) {
    this.sidebarShow = val
  }

  public toggleSidebarUnfoldable() {
    this.setSidebarUnfoldable(!this.sidebarUnfoldable)
  }

  public setSidebarUnfoldable(val: boolean) {
    this.sidebarUnfoldable = val
  }

  public getSiteSettings(): Promise<{ [key: string]: any }> {
    return this.serverStore
      .server()
      .get('/api/v1/site_settings')
      .then(({ data }) => data)
  }

  public setSiteSettings(siteSettings: { [key: string]: any }) {
    this.siteSettings = siteSettings
  }
}
