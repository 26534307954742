export type RestfulError = {
  status: number
  message: string
  error?: string // Error code
}
export const extractRestfulError = (error: any): RestfulError | undefined => {
  if (error?.response?.data) {
    return error.response.data
  }
  return undefined
}

export class VisionRequestInterceptedError extends Error {}
