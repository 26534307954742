import React from 'react'
import { CAlert } from '@coreui/react'

type TProps = {
  color?: string
  message: string | undefined
  onToggleAlert?: () => void
  'data-testid'?: string
}

export const Alert = (props: TProps) => {
  return (
    <CAlert
      data-testid={`${props['data-testid'] ? props['data-testid'] + '-' : ''}alert`}
      visible
      variant="solid"
      color={props.color || 'info'}
    >
      <p>{props.message}</p>
      {props.onToggleAlert && (
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          data-testid="close-alert-btn"
          onClick={() => props.onToggleAlert!()}
        />
      )}
    </CAlert>
  )
}
