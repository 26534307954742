import { CFormInputProps } from '@coreui/react/dist/components/form/CFormInput'
import React, { InputHTMLAttributes } from 'react'
import { CFormFeedback, CFormInput } from '@coreui/react'
import classNames from 'classnames'
import { useValidation } from '../../lib/hooks'

export type ValidatedInputProps<P> = Omit<P, 'onChange' | 'required'> & {
  value?: string
  required?: boolean
  asterisk?: string
  validate: (v: string) => string | undefined
  onChange: (v: string | undefined) => any
  renderInvalidMessage: (invalidReason: string | undefined) => string | undefined
}

/**
 * Helper wrapper component around CoreUI CFormInput to consistently apply more modern reactive validation
 * @param props
 * @constructor
 */
export function ValidatedCFormInput(
  props: ValidatedInputProps<CFormInputProps & InputHTMLAttributes<HTMLInputElement>>,
) {
  const { value, validate, renderInvalidMessage, onChange, required, ...rest } = props

  const {
    value: _value,
    setValue,
    isValid,
    invalidReason,
    showInvalidReason,
    invokeValidation,
    invalidMessage,
  } = useValidation({
    value: value || '',
    validate,
    required,
    onChange,
    renderInvalidMessage,
  })

  return (
    <section className={'ValidatedInput'}>
      {props.required && (
        <i
          className={classNames('fas fa-asterisk required-icon', {
            invalid: showInvalidReason && invalidReason,
            valid: isValid,
          })}
          data-testid={'test-required-icon'}
        />
      )}
      <CFormInput
        {...rest}
        value={_value}
        onChange={({ target: { value: v } }) => {
          setValue(v)
        }}
        valid={isValid}
        invalid={showInvalidReason && !!invalidReason}
        onBlur={() => invokeValidation()}
        required={required}
      />
      {showInvalidReason && (
        <CFormFeedback invalid>{invalidReason !== 'required' && invalidMessage}</CFormFeedback>
      )}
    </section>
  )
}
