import { CCol, CFormLabel, CRow } from '@coreui/react'
import React from 'react'
import { SingleImageUploader, ValidatedCFormInput } from '../../custom'
import { validateLength } from '../../../lib/helpers/validation'

export type SignupAccountData = {
  name: string
  logo: File | string
}
type TProps = {
  value: Partial<SignupAccountData>
  onChange: (value: Partial<SignupAccountData>) => void
}
export const SignupAccountForm = (props: TProps) => {
  return (
    <>
      <CRow className="mb-3">
        <CFormLabel {...{ htmlFor: 'signupAccountName' }} className="col-sm-2 col-form-label">
          Account Name
        </CFormLabel>
        <CCol>
          <ValidatedCFormInput
            type="text"
            id="signupAccountName"
            data-testid={'signupAccountName'}
            placeholder="Account Name"
            value={props.value.name || ''}
            onChange={(value) => props.onChange({ ...props.value, name: value })}
            required
            validate={(v) => validateLength(v, 2)}
            renderInvalidMessage={() => 'Must be a minimum of 2 characters'}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel {...{ htmlFor: 'signupAccountName' }} className="col-sm-2 col-form-label">
          Account Logo
        </CFormLabel>
        <CCol>
          <SingleImageUploader
            value={props.value.logo}
            onFile={(file) => props.onChange({ ...props.value, logo: file })}
            required
            data-testid="accountLogoUploader"
          />
        </CCol>
      </CRow>
    </>
  )
}
