import { CBadge, CCard, CCardBody, CCardSubtitle, CCardTitle, CCol, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { ApplicationStore, NotificationStore, ServerStore } from '../../stores'
import { inject, observer } from 'mobx-react'
import { IBusinessClassifications } from '../form/fragments/listing'

type TProps = {
  path: string
  notificationStore?: NotificationStore
  serverStore?: ServerStore
}
export const BusinessClassificationsCard = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [businessClassificationsData, setBusinessClassificationsData] = useState<
      Array<IBusinessClassifications> | undefined
    >(undefined)

    useEffect(() => {
      props.serverStore
        ?.server()
        .get<Array<IBusinessClassifications>>(props.path)
        .then(({ data }) => {
          setBusinessClassificationsData(data)
        })
        .catch(() => {
          props.notificationStore?.setNotificationMessage(
            'Failed to load listing business classifications data. Please try again.',
            'danger',
            3000,
          )
        })
    }, [props.path])
    return (
      <CCard className="listing-card">
        <CCardBody>
          <CCardTitle className="bold-text">Business Classifications</CCardTitle>
          <CCardSubtitle className="small-text">
            Business classifications can be explained in more detail on the {}
            <a href="https://www.sba.gov">Small Business Administration</a> website.
          </CCardSubtitle>

          <CRow className="mt-2 justify-content-md-start px-2">
            {businessClassificationsData?.map((bc) => {
              const id = `businessClassifications${bc.id}`

              return (
                <CCol key={id} data-testid={id} className={'col-md-auto px-1'}>
                  <CBadge color="primary" className="text-wrap text-md-start">
                    {bc.name}
                  </CBadge>
                </CCol>
              )
            })}
          </CRow>
        </CCardBody>
      </CCard>
    )
  }),
)
