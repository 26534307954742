import { ServerStore, UserData } from './'
import {
  CancelableStripeSessionParams,
  FileResponseData,
  PricingItemData,
  StripeSessionResponse,
} from '../lib/types'
import { PaginationParams } from '../lib/types/Params'

export interface UserListingAccessesAttributes {
  userId: number
  isPointOfContact: boolean
}

export interface ListingTechnologyTypesAttributes {
  technologyTypeId: number
}

export interface ListingNaicsCodesAttributes {
  naicsCodeId: number
}

export interface ListingBusinessClassificationsAttributes {
  businessClassificationId: number
}

export interface ListingSocialMediaAccountAttributes {
  socialMediaTypeId: number
  identifier: string
}

export interface CreateListingData {
  // ORGANIZATION DETAILS
  name: string
  tagLine: string
  logo: File | undefined
  yearFounded: string | number
  website: string
  userListingAccessesAttributes: Array<UserListingAccessesAttributes>
  // HEADQUARTERS
  addressLine1: string
  addressLine2?: string
  addressLine3?: string
  city: string
  stateOrProvince: string
  country: string
  postalCode: string
  // CONTRACT DETAILS
  samCode?: string
  dunsCode?: string
  uniqueEntityId: string
  listingNaicsCodesAttributes?: Array<ListingNaicsCodesAttributes>
  listingTechnologyTypesAttributes?: Array<ListingTechnologyTypesAttributes>
  capabilityStatement: string
  listingBusinessClassificationsAttributes?: Array<ListingBusinessClassificationsAttributes>
  listingSocialMediaAccountsAttributes?: Array<ListingSocialMediaAccountAttributes>
  // Pricing
  priceId: string
}

export enum ListingSubscriptionPaymentStatus {
  // Stripe statuses
  active = 'active',
  past_due = 'past_due',
  unpaid = 'unpaid',
  canceled = 'canceled',
  incomplete = 'incomplete',
  incomplete_expired = 'incomplete_expired',
  trialing = 'trialing',
  // Our custom statuses
  active_canceled = 'active_canceled',
  checkout_canceled = 'checkout_canceled',
  awaiting_checkout = 'awaiting_checkout',
  awaiting_payment_confirmation = 'awaiting_payment_confirmation',
}

export interface UpdateListingData {
  id: string | number
  name: string
  tagLine: string
  logo: File | FileResponseData
  yearFounded: string | number
  website: string
  pointOfContactId: string | number
  // HEADQUARTERS
  addressLine1: string
  addressLine2?: string
  addressLine3?: string
  city: string
  stateOrProvince: string
  country: string
  postalCode: string
  // CONTRACT DETAILS
  samCode?: string
  dunsCode?: string
  uniqueEntityId: string
  naicsCodes?: Array<DestructibleItem>
  technologyTypes?: Array<DestructibleItem>
  capabilityStatement: string
  businessClassifications?: Array<DestructibleItem>
  socialMediaAccounts?: Array<ListingSocialMediaAccountAttributes>
}

export type ListingSubscriptionData = {
  accountId: number
  paymentStatus: ListingSubscriptionPaymentStatus
  periodStart: string
  periodEnd: string
  cancelAt?: string
  canceledAt?: string
  cancelAtPeriodEnd: boolean
}

export type ListingData = {
  id: string | number
  addressLine1: string
  addressLine2?: string
  addressLine3?: string
  city: string
  stateProvince: string
  postalCode: string
  country: string
  capabilityStatement: string
  dunsCode?: string
  samCode?: string
  uniqueEntityId: string
  tagLine: string
  website: string
  accountId: string | number
  createdAt: string
  updatedAt: string
  inactiveDate: string
  yearFounded: string
  name: string
  logo: FileResponseData
  listingSocialMediaAccounts: string
  users: string
  businessClassifications: string
  naicsCodes: string
  technologyTypes: string
  offerings: string
  listingContracts: string
  isPublished?: boolean
  subscription: ListingSubscriptionData
}

export interface Destructible {
  destroyable?: boolean
  destroy?: boolean
}

export interface DestructibleItem extends Destructible {
  id: string | number
}

export class ListingStore extends ServerStore {
  public listings: any

  public getListings = async (params: PaginationParams) => {
    return this.server()
      .get('/api/v1/current_user/listings', {
        params: { page: params.page, perPage: params.perPage },
      })
      .then(({ data }) => data)
  }

  public getSingleListing = (id: string | number): Promise<ListingData> => {
    return this.server()
      .get(`/api/v1/listings/${id}`)
      .then(({ data }) => data)
  }

  public getPointOfContact = (id: string | number): Promise<UserData> => {
    return this.server()
      .get(`/api/v1/listings/${id}/users/point_of_contact`)
      .then(({ data }) => data)
  }

  public createListing = (
    payload: CreateListingData,
    checkoutParams: CancelableStripeSessionParams,
  ): Promise<StripeSessionResponse> => {
    const formData = new FormData()
    formData.append('priceId', payload.priceId)
    formData.append('name', payload.name)
    formData.append('tagLine', payload.tagLine)
    formData.append('logo', payload.logo as File)
    formData.append('yearFounded', payload.yearFounded.toString())
    formData.append('website', payload.website)
    payload.samCode && formData.append('samCode', payload.samCode)
    payload.dunsCode && formData.append('dunsCode', payload.dunsCode)
    formData.append('uniqueEntityId', payload.uniqueEntityId)
    formData.append('capabilityStatement', payload.capabilityStatement)

    // TODO ruby's underscor method on the backend doesn't propery handle these numbers
    formData.append('addressLine_1', payload.addressLine1)
    payload.addressLine2 && formData.append('addressLine_2', payload.addressLine2)
    payload.addressLine3 && formData.append('addressLine_3', payload.addressLine3)
    formData.append('city', payload.city)
    formData.append('stateProvince', payload.stateOrProvince)
    formData.append('country', payload.country)
    formData.append('postalCode', payload.postalCode)

    payload.userListingAccessesAttributes &&
      payload.userListingAccessesAttributes.forEach((el, i) => {
        formData.append(`userListingAccessesAttributes[${i}][userId]`, el.userId.toString())
        formData.append(
          `userListingAccessesAttributes[${i}][isPointOfContact]`,
          el.isPointOfContact.toString(),
        )
      })

    payload.listingNaicsCodesAttributes &&
      payload.listingNaicsCodesAttributes.forEach((el, i) => {
        formData.append(`listingNaicsCodesAttributes[${i}][naicsCodeId]`, el.naicsCodeId.toString())
      })

    payload.listingTechnologyTypesAttributes &&
      payload.listingTechnologyTypesAttributes.forEach((el, i) => {
        formData.append(
          `listingTechnologyTypesAttributes[${i}][technologyTypeId]`,
          el.technologyTypeId.toString(),
        )
      })

    payload.listingBusinessClassificationsAttributes &&
      payload.listingBusinessClassificationsAttributes?.forEach((el, i) => {
        formData.append(
          `listingBusinessClassificationsAttributes[${i}][businessClassificationId]`,
          el.businessClassificationId.toString(),
        )
      })

    payload.listingSocialMediaAccountsAttributes &&
      payload.listingSocialMediaAccountsAttributes.forEach((el, i) => {
        formData.append(
          `listingSocialMediaAccountsAttributes[${i}][socialMediaTypeId]`,
          el.socialMediaTypeId.toString(),
        )
        formData.append(
          `listingSocialMediaAccountsAttributes[${i}][identifier]`,
          el.identifier.toString(),
        )
      })

    checkoutParams.origin && formData.append('origin', checkoutParams.origin)
    formData.append('successRedirectPath', checkoutParams.successRedirectPath)
    formData.append('cancelRedirectPath', checkoutParams.cancelRedirectPath)

    return this.server()
      .post(`/api/v1/current_user/listings`, formData, {
        headers: { 'Content-Type': 'multipart-form-data' },
      })
      .then(({ data }) => data)
  }

  public updateListing = async (payload: UpdateListingData): Promise<ListingData> => {
    const formData = new FormData()
    formData.append('name', payload.name)
    formData.append('tagLine', payload.tagLine)
    if (payload.logo instanceof File) {
      formData.append('logo', payload.logo as File)
    }
    formData.append('yearFounded', payload.yearFounded.toString())
    formData.append('website', payload.website)
    payload.samCode && formData.append('samCode', payload.samCode)
    payload.dunsCode && formData.append('dunsCode', payload.dunsCode)
    formData.append('uniqueEntityId', payload.uniqueEntityId)
    formData.append('capabilityStatement', payload.capabilityStatement)

    formData.append('addressLine_1', payload.addressLine1)
    formData.append('addressLine_2', payload.addressLine2 ? payload.addressLine2 : '')
    formData.append('addressLine_3', payload.addressLine3 ? payload.addressLine3 : '')
    formData.append('city', payload.city)
    formData.append('stateProvince', payload.stateOrProvince)
    formData.append('country', payload.country)
    formData.append('postalCode', payload.postalCode)
    formData.append('pointOfContactId', payload.pointOfContactId.toString())

    payload.naicsCodes &&
      payload.naicsCodes.forEach((code) => {
        formData.append('naicsCodes[][id]', code.id.toString())
        formData.append('naicsCodes[][destroy]', code.destroy!.toString())
      })

    payload.technologyTypes &&
      payload.technologyTypes.forEach((type) => {
        formData.append('technologyTypes[][id]', type.id.toString())
        formData.append('technologyTypes[][destroy]', type.destroy!.toString())
      })

    payload.businessClassifications &&
      payload.businessClassifications.forEach((el) => {
        formData.append(`businessClassifications[][id]`, el.id.toString())
        formData.append(`businessClassifications[][destroy]`, el.destroy!.toString())
      })

    payload.socialMediaAccounts &&
      payload.socialMediaAccounts.forEach((el) => {
        formData.append(`socialMediaAccounts[][socialMediaTypeId]`, el.socialMediaTypeId.toString())
        formData.append(`socialMediaAccounts[][identifier]`, el.identifier.toString())
        formData.append(
          `socialMediaAccounts[][destroy]`,
          el.identifier.length < 2 ? 'true' : 'false',
        )
      })

    return this.server()
      .patch(`/api/v1/current_user/listings/${payload.id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(({ data }) => data)
  }

  public cancelListing(listingId: number | string): Promise<ListingData> {
    return this.server()
      .patch(`/api/v1/current_user/listings/${listingId}/cancel`)
      .then(({ data }) => data)
  }

  public renewListing(listingId: number | string): Promise<ListingData> {
    return this.server()
      .patch(`/api/v1/current_user/listings/${listingId}/renew`)
      .then(({ data }) => data)
  }

  public getListingPrices(): Promise<Array<PricingItemData>> {
    return this.server()
      .get('/api/v1/listings/pricing')
      .then(({ data }) => data)
  }

  public recordListingCheckoutSessionComplete(listingId: number | string): Promise<ListingData> {
    return this.server()
      .patch(`/api/v1/current_user/listings/${listingId}/record_checkout_session_success`)
      .then(({ data }) => data)
  }

  public recordListingCheckoutSessionCanceled(listingId: number | string): Promise<ListingData> {
    return this.server()
      .patch(`/api/v1/current_user/listings/${listingId}/record_checkout_session_canceled`)
      .then(({ data }) => data)
  }

  public openListingCheckout(
    listingId: number | string,
    priceId: string,
    checkoutParams: CancelableStripeSessionParams,
  ): Promise<StripeSessionResponse> {
    return this.server()
      .post(
        `/api/v1/current_user/listings/${listingId}/checkout`,
        { priceId, ...checkoutParams },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      .then(({ data }) => data)
  }

  public publishListing(listingId: number | string): Promise<ListingData> {
    return this.server()
      .patch(`/api/v1/current_user/listings/${listingId}/publish`)
      .then(({ data }) => data)
  }

  public unpublishListing(listingId: number | string): Promise<ListingData> {
    return this.server()
      .patch(`/api/v1/current_user/listings/${listingId}/unpublish`)
      .then(({ data }) => data)
  }
}
