import React, { useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardImage,
  CCardTitle,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import { AccountStore, ApplicationStore, NotificationStore, UserStore } from '../../stores'
import { inject, observer } from 'mobx-react'
import { EditAccountForm } from '../form'

type TProps = {
  userStore?: UserStore
  notificationStore?: NotificationStore
  accountStore?: AccountStore
}
export const AccountDetailCard = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.accountStore,
)(
  observer((props: TProps) => {
    const [isBillingButtonSuspended, setIsBillingButtonSuspended] = useState(false)
    const [isEditAccountModalOpen, setIsEditAccountModalOpen] = useState<boolean>(false)

    const onBillingClick = () => {
      setIsBillingButtonSuspended(true)
      props
        .accountStore!.createCustomerPortalSession({
          origin: window.location.origin,
          successRedirectPath: `${window.location.pathname}`,
        })
        .then((data) => {
          setTimeout(() => {
            window.location.href = data.sessionUrl
          }, 1000)
        })
        .catch(() => {
          setTimeout(() => {
            setIsBillingButtonSuspended(false)
            props.notificationStore?.setNotificationMessage(
              'Unable to connect to portal right now, please try again later.',
              'danger',
              3000,
            )
          }, 1000)
        })
    }

    return (
      <CContainer>
        <CCard className="ProfileDetailCard">
          <CCardHeader component="h5">Account Detail</CCardHeader>
          <CCardBody>
            <div className="card-image-container">
              <CCardImage
                orientation="top"
                {...{ src: props.userStore!.currentAccountData!.logo.path }}
              />
            </div>
          </CCardBody>
          <CCardFooter>
            <CCardTitle>
              <b>{props.userStore?.currentAccountData?.name}</b>
            </CCardTitle>

            {/* TODO admin only */}
            <CRow className="action-row">
              <CCol>
                <CButton
                  color="success"
                  variant="outline"
                  data-testid="open-edit-account-btn"
                  onClick={() => setIsEditAccountModalOpen(true)}
                >
                  <i className="fas fa-pencil-alt" />
                  Edit Account
                </CButton>
              </CCol>
              <CCol>
                <CButton
                  color="dark"
                  variant={!isBillingButtonSuspended ? 'outline' : undefined}
                  active={isBillingButtonSuspended}
                  onClick={() => onBillingClick()}
                  disabled={isBillingButtonSuspended}
                  data-testid="open-billing-portal-btn"
                >
                  {isBillingButtonSuspended ? (
                    <i data-testid="suspense-spinner" className="fa fa-spinner fa-spin" />
                  ) : (
                    <i className="fas fa-file-invoice-dollar"></i>
                  )}
                  Billing Portal
                </CButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>

        <CModal
          size="lg"
          alignment="center"
          visible={isEditAccountModalOpen}
          onClose={() => setIsEditAccountModalOpen(false)}
        >
          <CModalHeader>
            <CModalTitle>Edit Account</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CContainer>
              <EditAccountForm onSubmitted={() => setIsEditAccountModalOpen(false)} />
            </CContainer>
          </CModalBody>
        </CModal>
      </CContainer>
    )
  }),
)
