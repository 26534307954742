import React, { FormEvent, useEffect, useState } from 'react'
import { ApplicationStore, AuthStore, NotificationStore, UserStore } from '../stores'
import { inject, observer } from 'mobx-react'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { Redirect } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CRow,
} from '@coreui/react'
import { Spinner, SupportLinks } from '../components/custom'
import { extractRestfulError } from '../lib/errors/utils'
import { validateEmail } from '../lib/helpers/validation'

type TProps = {
  userStore?: UserStore
  notificationStore?: NotificationStore
  authStore?: AuthStore
}
export const ConfirmEmailLanding = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.authStore,
)(
  observer((props: TProps) => {
    const [params] = useQueryParams({
      confirmationToken: withDefault(StringParam, undefined),
    })
    const [success, setSuccess] = useState<boolean | undefined>(false)
    const [isSuspending, setIsSuspending] = useState<boolean>(true)
    const [knownErrorReason, setKnownErrorReason] = useState<string | undefined>(undefined)
    const [email, setEmail] = useState<string>('')

    useEffect(() => {
      if (params.confirmationToken) {
        // For suspense with other loaders (i.e. the AuthBoundary)
        setTimeout(() => {
          props.userStore
            ?.confirmUser(params.confirmationToken!)
            .then((result) => {
              setTimeout(() => {
                setSuccess(true)
                setIsSuspending(false)
              }, 1000)
            })
            .catch((error) => {
              setKnownErrorReason(extractRestfulError(error)?.error)
              setSuccess(false)
              setIsSuspending(false)
            })
        }, 1000)
      } else {
        setIsSuspending(false)
      }
    }, [])

    const resendConfirmationEmail = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      setIsSuspending(true)
      props.userStore
        ?.resendConfirmationEmail(email)
        .then(() => {
          props.notificationStore?.setNotificationMessage(
            'Resend confirmation email success.',
            'success',
            3000,
          )
        })
        .catch(() => {
          props.notificationStore?.setNotificationMessage(
            'Unable to resend confirmation email. Please try again.',
            'danger',
            3000,
          )
        })
      setIsSuspending(false)
    }

    const renderSuspense = () => {
      return (
        <>
          <CRow className="mb-2 justify-content-md-center">
            <CCol>
              <h2 className="text-center">Confirming your account...</h2>
            </CCol>
          </CRow>
          <CRow>
            <CCol className="d-flex justify-content-center">
              <Spinner />
            </CCol>
          </CRow>
        </>
      )
    }

    const determineFailureMessage = () => {
      // TODO pretty these messages up a bit
      switch (knownErrorReason) {
        case 'InvalidConfirmationToken':
          return 'The confirmation token is invalid.'
        case 'ConfirmationPeriodExpired':
          return 'This confirmation link has expired.'
        default:
          return 'There was a problem confirming your account.'
      }
    }

    const renderFailure = () => {
      return (
        <>
          <CRow className="mb-2 justify-content-md-center">
            <CCol>
              <h2 className="text-center">Failed to Confirm Account</h2>
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CCol>
              <p className="text-center subtle">{determineFailureMessage()}</p>
            </CCol>
          </CRow>

          <CRow className="mb-2 justify-content-md-center">
            <CCol>
              <p className="text-center">
                Use the form below to resend a confirmation email if needed. If the email provided
                is in our records and that account is not already confirmed, a new confirmation
                email will be sent.
              </p>
            </CCol>
          </CRow>
          <CForm onSubmit={resendConfirmationEmail}>
            <CRow className="mb-2 justify-content-center">
              <CCol className="d-flex justify-content-center">
                <CFormInput
                  type="email"
                  id="email-input"
                  data-testid="email-input"
                  placeholder="Email"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                  value={email}
                />
              </CCol>
            </CRow>
            <CRow className="mb-3 justify-content-center">
              <CCol className="d-flex justify-content-center">
                <CButton
                  data-testid="submit-button"
                  disabled={!!validateEmail(email) || isSuspending}
                  type="submit"
                >
                  Resend Confirmation Email
                </CButton>
              </CCol>
            </CRow>
          </CForm>
        </>
      )
    }

    const renderSuccess = () => {
      return (
        <>
          <CRow className="mb-2 justify-content-md-center">
            <CCol>
              <h2 className="text-center">Your account has been confirmed!</h2>
            </CCol>
          </CRow>
          <CRow className="mb-2 justify-content-center">
            <CCol className="d-flex justify-content-center">
              <CButton href="/">Continue</CButton>
            </CCol>
          </CRow>
        </>
      )
    }

    return (
      <section className="bg-light min-vh-100 d-flex flex-row align-items-center">
        {!params.confirmationToken && <Redirect to="/" />}
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={9} lg={7} xl={6}>
              <CCard>
                <CCardBody className="p-4">
                  {isSuspending && renderSuspense()}
                  {!isSuspending && (success ? renderSuccess() : renderFailure())}
                </CCardBody>
                <CCardFooter className="bg-white">
                  <CRow className="justify-content-center">
                    <CCol>
                      <SupportLinks />
                    </CCol>
                  </CRow>
                </CCardFooter>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </section>
    )
  }),
)

export default ConfirmEmailLanding
