import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, ListingStore, NotificationStore } from '../../stores'
import { useQueryParams, withDefault, NumberParam } from 'use-query-params'
import { Pagination } from '../../components/custom'
import { PaginationData } from '../../lib/types'
import { CButton } from '@coreui/react'
import { ListingData } from '../../stores/ListingStore'
import { ListingCard } from '../../components/listing'

type TProps = {
  applicationStore?: ApplicationStore
  listingStore?: ListingStore
  notificationStore?: NotificationStore
}

const Listings = inject(
  ApplicationStore.names.listingStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [listings, setListings] = useState<Array<ListingData> | undefined>([])
    const [pagination, setPagination] = useState<PaginationData | undefined>(undefined)
    const [paginationParams] = useQueryParams({
      page: withDefault(NumberParam, 1),
      perPage: withDefault(NumberParam, 10),
    })

    const loadData = () => {
      props.listingStore
        ?.getListings(paginationParams)
        .then((res) => {
          setListings(res.data)
          setPagination(res.pagination)
        })
        .catch(() => {
          props.notificationStore?.setNotificationMessage(
            'Unable to get listings now, please try again later',
            'danger',
          )
        })
    }

    useEffect(() => {
      loadData()
    }, [paginationParams])

    return (
      <section className="Listings">
        <div className="sub-header">
          <h5>My Listings</h5>
          <CButton
            className="add-new-listings-btn success"
            data-testid="redirect-to-create"
            href="/listings/new"
          >
            <i className="fas fa-plus" />
            New Listing
          </CButton>
        </div>

        <div className="company-card-container">
          {listings?.map((l: ListingData) => {
            return <ListingCard key={l.id} listing={l} onAction={() => loadData()} />
          })}
        </div>

        {pagination && <Pagination pagination={pagination} />}
      </section>
    )
  }),
)

export default Listings
