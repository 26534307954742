import React, { useEffect, useState } from 'react'
import { ApplicationStore, NotificationStore, ServerStore } from '../../stores'
import { inject, observer } from 'mobx-react'
import { SocialMediaAccount } from '../../stores/UserStore'
import { CCol, CRow } from '@coreui/react'
import { determineSocialMediaIconClassName } from '../../lib/helpers/utils'

type TProps = {
  path: string
  notificationStore?: NotificationStore
  serverStore?: ServerStore
}
export const SocialMediaDetails = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [socialMediaData, setSocialMediaData] = useState<Array<SocialMediaAccount> | undefined>(
      undefined,
    )

    useEffect(() => {
      props.serverStore
        ?.server()
        .get<Array<SocialMediaAccount>>(props.path)
        .then(({ data }) => {
          setSocialMediaData(data)
        })
        .catch(() => {
          props.notificationStore?.setNotificationMessage(
            'Failed to load listing social media data. Please try again.',
            'danger',
            3000,
          )
        })
    }, [props.path])
    return (
      <>
        <strong>Social Media</strong>
        {socialMediaData?.map((sma) => {
          const id = `socialMedia${sma.socialMediaTypeName.replace(' ', '')}${sma.id}`

          return (
            <CRow key={id} data-testid={id}>
              <CCol>
                <i className={determineSocialMediaIconClassName(sma.socialMediaTypeName)} />
                <span>{sma.identifier}</span>
              </CCol>
            </CRow>
          )
        })}
      </>
    )
  }),
)
