import React, { FormEvent, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CRow,
} from '@coreui/react'
import { ApplicationStore, AuthStore, NotificationStore, UserStore } from '../stores'
import { inject, observer } from 'mobx-react'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { validateEmail } from '../lib/helpers/validation'
import { SupportLinks } from '../components/custom'
type TProps = {
  userStore?: UserStore
  authStore?: AuthStore
  notificationStore?: NotificationStore
}

export const ConfirmationInstructions = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.authStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [params] = useQueryParams({
      key: withDefault(StringParam, ''),
    })
    const decodeKey = () => {
      if (params.key) {
        let buff = Buffer.from(params.key, 'base64')
        return buff.toString('ascii')
      } else {
        return ''
      }
    }
    const [email, setEmail] = useState<string>(decodeKey())
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const isResendEnabled =
      !isSubmitting && (!validateEmail(email) || props.authStore?.isAuthenticated())

    const resendConfirmationEmail = (e: FormEvent<HTMLFormElement>) => {
      setIsSubmitting(true)

      e.preventDefault()
      props.userStore
        ?.resendConfirmationEmail(email)
        .then(() => {
          setIsSubmitting(false)
          props.notificationStore?.setNotificationMessage(
            'A new confirmation email has been sent.',
            'success',
            3000,
          )
        })
        .catch(() => {
          setIsSubmitting(false)
          props.notificationStore?.setNotificationMessage(
            'Unable to resend confirmation email. Please try again.',
            'danger',
            3000,
          )
        })
    }

    const determineContent = () => {
      if (!props.authStore?.JWT && !params.key) {
        return emailForm()
      } else {
        return resendButton()
      }
    }

    const emailForm = () => {
      return (
        <section>
          <CRow className="mb-2 justify-content-center">
            <CCol className="d-flex justify-content-center">
              <CFormInput
                type="email"
                id="email-input"
                data-testid="email-input"
                placeholder="Email"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                value={email}
              />
            </CCol>
          </CRow>
          <CRow className="mb-2 justify-content-center">{resendButton()}</CRow>
        </section>
      )
    }

    const resendButton = () => {
      return (
        <CCol className="d-flex justify-content-center">
          <CButton data-testid="submit-button" type="submit" disabled={!isResendEnabled}>
            Resend Confirmation Email
          </CButton>
        </CCol>
      )
    }

    return (
      <section className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={9} lg={7} xl={6}>
              <CCard>
                <CCardBody className="p-4">
                  <CRow>
                    <CCol>
                      <h1 className="text-center">Please Confirm Your Email</h1>
                    </CCol>
                  </CRow>
                  <CRow className="mb-3">
                    <CCol>
                      <p className="text-center">
                        An email has been sent to the email address you have provided. Please check
                        your inbox and follow the link to confirm your account.
                      </p>
                      <br></br>
                      <p className="text-center">
                        If you did not receive an email or the link did not work, please resend the
                        confirmation email.
                      </p>
                    </CCol>
                  </CRow>
                  <CForm onSubmit={resendConfirmationEmail}>{determineContent()}</CForm>
                </CCardBody>
                <CCardFooter className="bg-white">
                  <CRow className="justify-content-center">
                    <CCol>
                      <SupportLinks />
                    </CCol>
                  </CRow>
                </CCardFooter>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </section>
    )
  }),
)

export default ConfirmationInstructions
