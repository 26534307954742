import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, AuthStore, NotificationStore, UserStore } from '../../stores'
import { PropsWithChildren } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import {
  SignIn,
  Signup,
  ConfirmationInstructions,
  SignTermsAndPrivacy,
  ConfirmEmailLanding,
  SetupPayment,
  RequestPasswordReset,
  ResetPassword,
  Terms,
  Privacy,
  AntiSpamPolicy,
} from '../../views'
import { VisionRequestInterceptedError } from '../../lib/errors/utils'

type TProps = {
  authStore?: AuthStore
  userStore?: UserStore
  notificationStore?: NotificationStore
}
export const AuthBoundary = inject(
  ApplicationStore.names.authStore,
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: PropsWithChildren<TProps>) => {
    useEffect(() => {
      if (props.authStore?.JWT) {
        Promise.all([
          props.userStore!.retrieveCurrentUserData(),
          props.userStore!.retrieveCurrentAccountData(),
        ])

          .then(([userData, accountData]) => {
            props.userStore?.setCurrentUserData(userData)
            props.userStore?.setCurrentAccountData(accountData)
          })
          .catch((err) => {
            if (!(err instanceof VisionRequestInterceptedError)) {
              debugger
              props.notificationStore?.setNotificationMessage(
                'Failed to load user and account data. Please try again',
                'danger',
                3000,
              )
            }
          })
      }
    }, [props.authStore!.salt])

    const authenticated = props.authStore?.isAuthenticated()
    const confirmed = props.userStore?.currentUserData?.confirmed
    return (
      <>
        <Switch>
          {!authenticated && <Route exact path="/signin" component={SignIn} />}
          {!authenticated && <Route exact path="/signup" component={Signup} />}
          {!authenticated && (
            <Route exact path="/request_password_reset" component={RequestPasswordReset} />
          )}
          {!authenticated && <Route exact path="/reset_password" component={ResetPassword} />}
          <Route exact path="/confirmation_instructions" component={ConfirmationInstructions} />
          {authenticated && <Route exact path="/sign_terms" component={SignTermsAndPrivacy} />}
          <Route exact path="/confirm_email" component={ConfirmEmailLanding} />
          {!authenticated && <Route exact path="/terms" component={Terms} />}
          {!authenticated && <Route exact path="/privacy" component={Privacy} />}
          {!authenticated && <Route exact path="/anti_spam" component={AntiSpamPolicy} />}
          {!authenticated && <Redirect to="/signin" />}
          {authenticated && <Route exact path="/setup_payment" component={SetupPayment} />}
          {authenticated && confirmed && props.userStore?.currentAccountData && props.children}
        </Switch>
      </>
    )
  }),
)
