import React, { SyntheticEvent, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, NotificationStore, ListingStore, UserStore } from '../../stores'
import {
  CCard,
  CCardHeader,
  CCardBody,
  CButtonGroup,
  CButton,
  CRow,
  CCol,
  CForm,
} from '@coreui/react'
import { OrganizationDetails, ContractingDetails } from '../../components/custom'
import { ListingOrganizationFormData } from '../../components/form/fragments/listing/OrganizationDetails'
import { ListingContractingDetailsFormData } from '../../components/form/fragments/listing/ContractingDetails'
import { CreateListingData } from '../../stores/ListingStore'
import { PricingItemData } from '../../lib/types'
import { PricingDataSelector } from '../../components/pricing'
import { formatPricingCost, isListingSubmittable } from '../../lib/helpers/utils'

type TProps = {
  listingStore?: ListingStore
  notificationStore?: NotificationStore
  userStore?: UserStore
}

const AddNew = inject(
  ApplicationStore.names.listingStore,
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.userStore,
)(
  observer((props: TProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [hasValidated, setHasValidated] = useState<boolean>(false)
    const [organizationFormData, setOrganizationFormData] = useState<
      Partial<ListingOrganizationFormData>
    >({
      pointOfContact: props.userStore?.currentUserData,
    })
    const [contractingFormData, setContractingFormData] = useState<
      Partial<ListingContractingDetailsFormData>
    >({})
    const [pricing, setPricing] = useState<PricingItemData | undefined>(undefined)

    const isSubmittable = () => {
      return isListingSubmittable(organizationFormData, contractingFormData) && pricing
    }

    const composeCreateListingBody = (): CreateListingData => {
      return {
        priceId: pricing!.id,
        name: organizationFormData.name!,
        tagLine: organizationFormData.tagLine!,
        logo: organizationFormData.logo,
        yearFounded: organizationFormData.yearFounded!,
        website: organizationFormData.website!,
        samCode: contractingFormData.samCode,
        dunsCode: contractingFormData.dunsCode,
        uniqueEntityId: contractingFormData.uniqueEntityId!,
        addressLine1: organizationFormData.headquarters!.addressLine1!,
        addressLine2: organizationFormData.headquarters!.addressLine2,
        addressLine3: organizationFormData.headquarters!.addressLine3,
        city: organizationFormData.headquarters!.city!,
        stateOrProvince: organizationFormData.headquarters!.stateOrProvince!,
        country: organizationFormData.headquarters!.country!,
        postalCode: organizationFormData.headquarters!.postalCode!,
        capabilityStatement: contractingFormData.capabilityStatement!,
        userListingAccessesAttributes: [
          { isPointOfContact: true, userId: organizationFormData.pointOfContact!.id },
        ],
        listingNaicsCodesAttributes: contractingFormData.naicsCodes?.map((nc) => {
          return { naicsCodeId: nc.id }
        }),
        listingTechnologyTypesAttributes: contractingFormData.technologyTypes?.map((t) => {
          return { technologyTypeId: t.id }
        }),
        listingBusinessClassificationsAttributes: contractingFormData.businessClassifications?.map(
          (bc) => {
            return { businessClassificationId: bc.id }
          },
        ),
        listingSocialMediaAccountsAttributes: organizationFormData.socialMedia,
      }
    }

    const onSubmit = (e: SyntheticEvent) => {
      e.preventDefault()

      setHasValidated(true)
      setIsSubmitting(true)

      const payload = composeCreateListingBody()

      isListingSubmittable(organizationFormData, contractingFormData) &&
        props.listingStore
          ?.createListing(payload, {
            origin: window.location.origin,
            // TODO these landing pages
            successRedirectPath: `/listings`,
            cancelRedirectPath: `/listings`,
          })
          .then((resp) => {
            // Redirect the user to the stripe checkout session
            window.location.href = resp.sessionUrl
          })
          .catch(() => {
            setIsSubmitting(false)
            props.notificationStore?.setNotificationMessage(
              'Something went wrong, please try again',
              'danger',
              3000,
            )
          })
    }

    return (
      <section className="AddNew">
        <CCard>
          <CCardHeader>
            Pricing
            <CButtonGroup role="group">
              <CButton
                disabled={!isSubmittable() || isSubmitting}
                className="checkout-btn"
                onClick={(e) => onSubmit(e)}
                data-testid={'checkout-btn'}
              >
                <i className="fas fa-shopping-cart" />
                Check Out
              </CButton>
              <CButton className="cancel-btn" href="/listings">
                Cancel
              </CButton>
            </CButtonGroup>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol md={8} className="mr-3">
                <PricingDataSelector
                  getPricingData={() => props.listingStore!.getListingPrices()}
                  onSelect={setPricing}
                  selected={pricing}
                />
              </CCol>
              <CCol md={4} className="border-left">
                <CRow>
                  <CCol>
                    <h3>Total: {formatPricingCost(pricing)}</h3>
                  </CCol>
                </CRow>
                <p className="subtle">* Listings can take up to 4 weeks for approval.</p>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <CRow className="align-items-center">
          <CForm
            onSubmit={onSubmit}
            validated={hasValidated}
            className="row g-3 needs-validation"
            {...{ noValidate: true }}
          >
            <CCol className="panel-column left">
              <OrganizationDetails
                value={organizationFormData}
                onChange={(change) => setOrganizationFormData(change)}
              />
            </CCol>
            <CCol className="panel-column right">
              <ContractingDetails
                value={contractingFormData}
                onChange={(change) => setContractingFormData(change)}
              />
            </CCol>
          </CForm>
        </CRow>
      </section>
    )
  }),
)

export default AddNew
