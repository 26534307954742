import { CBadge } from '@coreui/react'
import React from 'react'
import { ListingData } from '../../stores/ListingStore'

export type TProps = {
  listing: ListingData
}
export const ListingPublishedStatusIndicator = (props: TProps) => {
  return props.listing.isPublished ? (
    <CBadge className="listings-status-indicator align-middle published" color="primary">
      Published
    </CBadge>
  ) : (
    <CBadge className="listings-status-indicator align-middle" color="secondary">
      Unpublished
    </CBadge>
  )
}
