import { CCol, CFormLabel, CRow } from '@coreui/react'
import React from 'react'
import {
  validateEmail,
  validatePassword,
  validateValueMatch,
} from '../../../lib/helpers/validation'
import { ValidatedCFormInput } from '../../custom'

export type SignupLoginData = {
  email: string
  password: string
  confirmPassword: string
}
type TProps = {
  value: Partial<SignupLoginData>
  onChange: (value: Partial<SignupLoginData>) => void
}

export const SignupLoginForm = (props: TProps) => {
  const onChange = (change: Partial<SignupLoginData>) =>
    props.onChange({ ...props.value, ...change })

  return (
    <>
      <CRow className="mb-3">
        <CFormLabel {...{ htmlFor: 'signupUserEmail' }} className="col-sm-2 col-form-label">
          Email
        </CFormLabel>

        <CCol>
          <ValidatedCFormInput
            type="email"
            id="signupUserEmail"
            data-testid="signupUserEmail"
            placeholder="Email"
            value={props.value.email}
            required
            onChange={(email: string | undefined) => {
              onChange({ email })
            }}
            validate={validateEmail}
            renderInvalidMessage={() => 'Provide a valid email'}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel {...{ htmlFor: 'signupUserPassword' }} className="col-sm-2 col-form-label">
          Password
        </CFormLabel>

        <CCol>
          <ValidatedCFormInput
            type="password"
            id="signupUserPassword"
            data-testid="signupUserPassword"
            placeholder="Password"
            value={props.value.password || ''}
            onChange={(password: string | undefined) => onChange({ password })}
            required
            validate={(v) => validatePassword(v, 6)}
            renderInvalidMessage={() =>
              'Must be at least 6 characters, contain at least one capital and lowercase letter, number, and special character'
            }
          />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel
          {...{ htmlFor: 'signupUserConfirmPassword' }}
          className="col-sm-2 col-form-label"
        >
          Confirm Password
        </CFormLabel>

        <CCol>
          <ValidatedCFormInput
            type="password"
            id="signupUserConfirmPassword"
            data-testid="signupUserConfirmPassword"
            placeholder="Confirm Password"
            value={props.value.confirmPassword || ''}
            required
            onChange={(confirmPassword: string | undefined) => onChange({ confirmPassword })}
            validate={(v) => validateValueMatch(props.value.password, v)}
            renderInvalidMessage={() => 'Passwords must match'}
          />
        </CCol>
      </CRow>
    </>
  )
}
