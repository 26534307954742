import { ServerStore } from './ServerStore'
import {
  StripeSessionParams,
  CancelableStripeSessionParams,
  StripeSessionResponse,
} from '../lib/types'
import { AccountData, UserData } from './UserStore'

export type TermsOrPrivacyResponse = {
  id: string | number
  title: string
  content: string
  publishDate: string
}
export class AccountStore extends ServerStore {
  public baseCurrentUserURL = '/api/v1/current_user'

  public getAccountUsers(): Promise<Array<UserData>> {
    return this.server()
      .get(`${this.baseCurrentUserURL}/account/users`)
      .then(({ data }) => data)
  }

  public createStripeSetupSession(
    params: CancelableStripeSessionParams,
  ): Promise<StripeSessionResponse> {
    return this.server()
      .post(`${this.baseCurrentUserURL}/account/setup_session`, params)
      .then(({ data }) => data)
  }

  public createCustomerPortalSession(params: StripeSessionParams): Promise<StripeSessionResponse> {
    return this.server()
      .post(`${this.baseCurrentUserURL}/account/customer_portal_session`, params)
      .then(({ data }) => data)
  }

  public getLatestTerms(): Promise<TermsOrPrivacyResponse> {
    return this.server()
      .get<TermsOrPrivacyResponse>('/api/v1/terms')
      .then(({ data }) => data)
  }

  public getLatestPrivacy(): Promise<TermsOrPrivacyResponse> {
    return this.server()
      .get<TermsOrPrivacyResponse>('/api/v1/privacy')
      .then(({ data }) => data)
  }

  public getLatestAntiSpamPolicy(): Promise<TermsOrPrivacyResponse> {
    return this.server()
      .get<TermsOrPrivacyResponse>('/api/v1/anti_spam_policy')
      .then(({ data }) => data)
  }

  public signTerms(termId: string | number) {
    return this.server().post(`/api/v1/terms/${termId}/sign`)
  }

  public signPrivacy(privacyId: string | number) {
    return this.server().post(`/api/v1/privacy/${privacyId}/sign`)
  }

  public recordSetupPaymentSessionComplete(): Promise<AccountData> {
    return this.server()
      .patch(`/api/v1/current_user/account/mark_setup_session_complete`)
      .then(({ data }) => data)
  }
}
