import React from 'react'

export const cloudUploadIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 60" x="0px" y="0px">
      <title>Cloud_Upload</title>
      <path
        d="M50,44.83V80H46V44.83L33.41,57.41l-2.82-2.82L48,37.17,65.41,54.59l-2.82,2.82Zm37.79-8.35A18.1,18.1,0,0,0,79,30.71,16,16,0,0,0,63,16a15.77,15.77,0,0,0-3.72.45A24,24,0,0,0,17,30.71,18,18,0,0,0,4,48,18.06,18.06,0,0,0,22,66H42V62H22a14,14,0,0,1-2.61-27.75L21,33.94V32.29c0-.09,0-.17,0-.29A20,20,0,0,1,57,19.94l.86,1.14,1.35-.45A12.24,12.24,0,0,1,63,20,12,12,0,0,1,75,32a2,2,0,0,0,0,.25v1.65l1.62.31A14.16,14.16,0,0,1,84.73,39,14,14,0,0,1,74,62H54v4H74A18,18,0,0,0,87.79,36.48Z"
        transform="translate(-4 -8)"
        fill="#d9d9d9"
      />
    </svg>
  )
}
