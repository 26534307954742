import { AuthStore, NotificationStore, ServerStore } from './'
import { action, makeObservable, observable } from 'mobx'
import { FileResponseData } from '../lib/types'

export type SocialMediaAccount = {
  socialMediaTypeName: string
  socialMediaTypeId: number
  identifier: string
  id: number
}

export interface UserData {
  id: number
  accountId: number
  firstName: string
  lastName: string
  email: string
  title: string
  timezone: string
  cellPhone: string
  workPhone?: string
  workPhoneExt?: string
  headshot: FileResponseData
  socialMediaAccounts: Array<SocialMediaAccount>
  listings: string
  isAdmin: boolean
  confirmed: boolean
  unconfirmedEmail?: string
}

export type UserSignupForm = {
  user: {
    email: string
    password: string
    confirmPassword: string
    firstName: string
    lastName: string
    title: string
    cellPhone: string
    workPhone?: string
    workPhoneExt?: string
    timezone: string
    headshot: File
    socialMedia?: Array<{
      socialMediaTypeId: number | string
      identifier: string
    }>
  }
  account: {
    name: string
    logo: File
  }
  recaptchaToken: string
}

export type UpdateUserForm = {
  firstName: string
  lastName: string
  title: string
  cellPhone: string
  workPhone?: string
  workPhoneExt?: string
  timezone: string
  headshot?: File
  socialMedia?: Array<{
    id?: number
    socialMediaTypeId: number | string
    identifier: string
    destroy?: boolean
  }>
}

export type AccountData = {
  id: number
  name: string
  logo: FileResponseData
}

export type ChangePasswordData = {
  currentPassword: string
  password: string
  passwordConfirmation: string
}

export type UpdateAccountForm = {
  name: string
  logo?: File
}

export class UserStore extends ServerStore {
  public readonly baseUserURL: string = '/api/v1/users'
  public currentUserData?: UserData = undefined
  public currentAccountData?: AccountData = undefined

  constructor(authStore: AuthStore, notificationStore: NotificationStore) {
    super(authStore, notificationStore)
    makeObservable(this, {
      currentUserData: observable,
      setCurrentUserData: action,
      currentAccountData: observable,
      setCurrentAccountData: action,
    })
  }

  public setCurrentUserData(currentUserData: UserData) {
    this.currentUserData = currentUserData
  }

  public setCurrentAccountData(currentAccountData: AccountData) {
    this.currentAccountData = currentAccountData
  }

  public retrieveCurrentUserData = (): Promise<UserData> => {
    return this.server()
      .get<UserData>('/api/v1/current_user')
      .then(({ data }) => data)
  }

  public retrieveCurrentAccountData = (): Promise<AccountData> => {
    return this.server()
      .get<AccountData>('/api/v1/current_user/account')
      .then(({ data }) => data)
  }

  public signInUser = (email: string, password: string): Promise<any> => {
    const payload = { user: { email, password } }
    return this.server()
      .post(`${this.baseUserURL}/sign_in`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-User-Email': email,
        },
      })
      .then((res) => {
        this.authStore.setJWT(res.headers.authorization)
        return res.data
      })
  }

  public signOutUser = () => {
    // Intentionally abandoning this promise to the server.
    // The FE doesn't need to care if the serverside invalidation succeeded or not
    this.server().delete(`${this.baseUserURL}/sign_out`)

    this.authStore.setJWT(null)
  }

  public signUp = (form: UserSignupForm): Promise<UserData & { authorization: string }> => {
    const formData = new FormData()
    formData.append('recaptchaToken', form.recaptchaToken)
    formData.append('firstName', form.user.firstName)
    formData.append('lastName', form.user.lastName)
    formData.append('title', form.user.title)
    formData.append('cellPhone', form.user.cellPhone)
    formData.append('timezone', form.user.timezone)
    formData.append('headshot', form.user.headshot)
    form.user.workPhone && formData.append('workPhone', form.user.workPhone)
    form.user.workPhoneExt && formData.append('workPhoneExt', form.user.workPhoneExt)

    formData.append('account[name]', form.account.name)
    formData.append('account[logo]', form.account.logo)

    formData.append('user[email]', form.user.email)
    formData.append('user[password]', form.user.password)
    formData.append('user[passwordConfirmation]', form.user.confirmPassword)

    form.user.socialMedia &&
      form.user.socialMedia.forEach((sm, i) => {
        formData.append(
          `userSocialMediaAccountsAttributes[${i}][socialMediaTypeId]`,
          sm.socialMediaTypeId.toString(),
        )
        formData.append(
          `userSocialMediaAccountsAttributes[${i}][identifier]`,
          sm.identifier.toString(),
        )
      })

    return this.server()
      .post<UserData>('/api/v1/users', formData, {
        headers: { 'Content-Type': 'multipart-form-data' },
      })
      .then(({ data, headers: { authorization } }) => ({ ...data, authorization }))
  }

  public updateUser = (form: UpdateUserForm): Promise<UserData & { authorization: string }> => {
    const formData = new FormData()
    formData.append('firstName', form.firstName)
    formData.append('lastName', form.lastName)
    formData.append('title', form.title)
    formData.append('cellPhone', form.cellPhone)
    formData.append('timezone', form.timezone)
    form.headshot && formData.append('headshot', form.headshot)
    form.workPhone && formData.append('workPhone', form.workPhone)
    form.workPhoneExt && formData.append('workPhoneExt', form.workPhoneExt)

    form.socialMedia &&
      form.socialMedia.forEach((sm, i) => {
        formData.append(
          `userSocialMediaAccountsAttributes[${i}][socialMediaTypeId]`,
          sm.socialMediaTypeId.toString(),
        )
        formData.append(
          `userSocialMediaAccountsAttributes[${i}][identifier]`,
          sm.identifier.toString(),
        )

        sm.id && formData.append(`userSocialMediaAccountsAttributes[${i}][id]`, sm.id.toString())
        sm.destroy &&
          formData.append(
            `userSocialMediaAccountsAttributes[${i}][_destroy]`,
            sm.destroy.toString(),
          )
      })

    return this.server()
      .patch<UserData>('/api/v1/users', formData, {
        headers: { 'Content-Type': 'multipart-form-data' },
      })
      .then(({ data, headers: { authorization } }) => ({ ...data, authorization }))
  }

  public changePassword = (
    params: ChangePasswordData,
  ): Promise<UserData & { authorization: string }> => {
    return this.server()
      .patch<UserData>(
        '/api/v1/users',
        { user: params },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      .then(({ data, headers: { authorization } }) => ({ ...data, authorization }))
  }

  public resendConfirmationEmail(email?: string) {
    const payload = email ? { user: { email } } : {}
    return this.server()
      .post('/api/v1/users/confirmation', payload, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(({ data }) => data)
  }

  public confirmUser(token: string) {
    return this.server().get('/api/v1/users/confirmation', {
      params: { confirmation_token: token },
    })
  }

  public updateAccount = (form: UpdateAccountForm): Promise<AccountData> => {
    const formData = new FormData()
    formData.append('name', form.name)
    form.logo && formData.append('logo', form.logo)

    return this.server()
      .patch<AccountData>('/api/v1/current_user/account', formData, {
        headers: { 'Content-Type': 'multipart-form-data' },
      })
      .then(({ data }) => data)
  }

  public changeEmail = (email: string) => {
    return this.server()
      .patch('/api/v1/users', { user: { email: email } })
      .then(({ data }) => data)
  }

  public requestPasswordReset = (email: string): Promise<any> => {
    return this.server()
      .post<any>('/api/v1/users/password', { email: email })
      .then((res: any) => res)
  }

  public resetPassword = (
    password: string,
    passwordConfirmation: string,
    resetPasswordToken: string,
  ): Promise<any> => {
    const params = {
      password: password,
      passwordConfirmation: passwordConfirmation,
      resetPasswordToken: resetPasswordToken,
    }
    return this.server()
      .patch<any>('/api/v1/users/password', {
        user: params,
      })
      .then((res: any) => res)
  }

  public cancelEmailChange = (): Promise<UserData> => {
    return this.server()
      .patch('/api/v1/users/confirmation/cancel')
      .then(({ data }) => data)
  }
}
