import { CCol, CFormInput, CRow } from '@coreui/react'
import { CountrySelectInput, RegionSelectInput, ValidatedCFormInput } from '../../custom'
import React from 'react'
import { validateLength } from '../../../lib/helpers/validation'

export type AddressData = {
  addressLine1: string
  addressLine2?: string
  addressLine3?: string
  city: string
  stateOrProvince: string
  country: string
  postalCode: string
}

type TProps = {
  onChange: (value: Partial<AddressData>) => void
  value?: Partial<AddressData>
  idPrefix?: string
}

export const AddressForm = (props: TProps) => {
  const makeId = (value: string) => {
    if (props.idPrefix) {
      return `${props.idPrefix}-${value}`
    }
    return value
  }

  const onChange = (v: Partial<AddressData>) => {
    return props.onChange({ ...props.value, ...v })
  }

  return (
    <section className="AddressForm">
      <CRow className="mb-1" xs={{ gutter: 2 }}>
        <CCol>
          <ValidatedCFormInput
            id={makeId('addressLine1Input')}
            value={props.value?.addressLine1 || ''}
            placeholder={'Address Line 1'}
            onChange={(value) => onChange({ addressLine1: value })}
            required
            data-testid={makeId('test-addressLine1Input')}
            validate={(v) => validateLength(v, 2)}
            renderInvalidMessage={() => 'Must be at least 2 characters'}
          />
        </CCol>
      </CRow>

      <CRow className="mb-1" xs={{ gutter: 2 }}>
        <CCol>
          <CFormInput
            id={makeId('addressLine2Input')}
            value={props.value?.addressLine2 || ''}
            placeholder="Address Line 2"
            onChange={({ target: { value } }) => onChange({ addressLine2: value })}
            data-testid={makeId('test-addressLine2Input')}
          />
        </CCol>
      </CRow>

      <CRow className="mb-1" xs={{ gutter: 2 }}>
        <CCol>
          <CFormInput
            id={makeId('addressLine3Input')}
            value={props.value?.addressLine3 || ''}
            placeholder="Address Line 3"
            onChange={({ target: { value } }) => onChange({ addressLine3: value })}
            data-testid={makeId('test-addressLine3Input')}
          />
        </CCol>
      </CRow>

      <CRow className="mb-1 justify-content-between" xs={{ gutter: 2 }}>
        <CCol>
          <ValidatedCFormInput
            id={makeId('addressCityInput')}
            value={props.value?.city || ''}
            placeholder="City"
            onChange={(value) => onChange({ city: value })}
            required
            data-testid={makeId('test-addressCityInput')}
            validate={(v) => validateLength(v, 2)}
            renderInvalidMessage={() => 'Must be at least 2 characters'}
          />
        </CCol>

        <CCol className="mb-1 region-select-col">
          <RegionSelectInput
            value={props.value?.stateOrProvince}
            onSelect={(r) => onChange({ stateOrProvince: r?.short })}
            country={props.value?.country}
            id={makeId('addressStateProvinceInput')}
            required
            data-testid={makeId('test-addressStateProvinceInput')}
          />
        </CCol>
      </CRow>

      <CRow className="mb-1">
        <CCol>
          <CountrySelectInput
            id={makeId('addressCountryInput')}
            value={props.value?.country || 'US'}
            onSelect={(v) => onChange({ country: v?.short, stateOrProvince: undefined })}
            required
            data-testid={makeId('test-addressCountryInput')}
          />
        </CCol>
        <CCol>
          <ValidatedCFormInput
            id={makeId('addressPostalCodeInput')}
            value={props.value?.postalCode || ''}
            placeholder="Postal Code"
            onChange={(value) => onChange({ postalCode: value })}
            required
            data-testid={makeId('test-addressPostalCodeInput')}
            validate={(v) => validateLength(v, 2)}
            renderInvalidMessage={() => 'Must be at least 2 characters'}
          />
        </CCol>
      </CRow>
    </section>
  )
}
