import React, { useEffect, useState } from 'react'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'
import { arrayContainsAny, objectIsEmpty } from '../../lib/helpers/utils'
import { AddressForm } from '../form/fragments'
import { inject, observer } from 'mobx-react'
import { ApplicationStore } from '../../stores'

export type GoogleAddressData = {
  addressLine1: string
  addressLine1Parts?: {
    streetNumber?: string
    street?: string
  }
  addressLine2?: string
  addressLine3?: string
  city: string
  stateOrProvince: string
  country: string
  postalCode: string
}

export type GoogleSuggestAddressInputProps = {
  onChange: (value: Partial<GoogleAddressData> | undefined) => void
  value: Partial<GoogleAddressData>
  required?: boolean
  applicationStore?: ApplicationStore
  isEditForm?: boolean
}

/**
 * Wrapper for google address select
 * @param props
 * @constructor
 */
export const GoogleSuggestAddressInput = inject('applicationStore')(
  observer((props: GoogleSuggestAddressInputProps) => {
    const [googlePlaceData, setGooglePlaceData] = useState<any>()
    const [rawFormVisible, setRawFormVisible] = useState<boolean>(false)

    useEffect(() => {
      const func = async () => {
        if (googlePlaceData) {
          const geocodeObj =
            googlePlaceData &&
            googlePlaceData.value &&
            (await geocodeByPlaceId(googlePlaceData.value.place_id))
          const addressObject = geocodeObj && getAddressObject(geocodeObj[0].address_components)
          props.onChange(addressObject)
        } else {
          props.onChange(undefined)
        }
      }
      func()
    }, [googlePlaceData])

    useEffect(() => {
      if (props.value && !objectIsEmpty(props.value)) {
        props.onChange(props.value)
        setRawFormVisible(true)
      }
    }, [props.value])

    const getAddressObject = (address_components: Array<google.maps.GeocoderAddressComponent>) => {
      // TODO Address lines 2 and 3
      const normalized = address_components.reduce(
        (agg: Partial<GoogleAddressData>, c: google.maps.GeocoderAddressComponent) => {
          if (c.types.includes('street_number')) {
            return {
              ...agg,
              addressLine1Parts: { ...agg.addressLine1Parts, streetNumber: c.long_name },
            }
          } else if (arrayContainsAny(c.types, ['street_address', 'route'])) {
            return {
              ...agg,
              addressLine1Parts: { ...agg.addressLine1Parts, street: c.long_name },
            }
          } else if (c.types.includes('administrative_area_level_1')) {
            return {
              ...agg,
              stateOrProvince: c.short_name,
            }
          } else if (c.types.includes('locality')) {
            return {
              ...agg,
              city: c.long_name,
            }
          } else if (c.types.includes('country')) {
            return {
              ...agg,
              country: c.short_name,
            }
          } else if (c.types.includes('postal_code')) {
            return {
              ...agg,
              postalCode: c.long_name,
            }
          }
          return agg
        },
        {} as Partial<GoogleAddressData>,
      )

      if (normalized.addressLine1Parts?.street && normalized.addressLine1Parts.streetNumber) {
        normalized.addressLine1 = `${normalized.addressLine1Parts.streetNumber} ${normalized.addressLine1Parts.street}`
      }
      return normalized
    }

    return (
      <section className="GoogleSuggestAddressInput">
        {props.required && (
          <i className="fas fa-asterisk required-icon" data-testid={'test-required-icon'} />
        )}
        <div className="select-container">
          <GooglePlacesAutocomplete
            apiKey={props.applicationStore!.siteSettings!['googleApiKey']!}
            debounce={100}
            minLengthAutocomplete={3}
            selectProps={{
              isClearable: true,
              placeholder: 'Search address...',
              class: 'GoogleSuggestSelect',
              onChange: (result: any) => {
                setGooglePlaceData(result)
              },
            }}
          />
          {!rawFormVisible && (
            <span
              className="manual-raw-inputs-selector subtle"
              data-testid="manual-raw-address-selector"
              onClick={() => setRawFormVisible(true)}
            >
              + Enter address manually
            </span>
          )}
        </div>

        {rawFormVisible && (
          <AddressForm
            onChange={(change) => props.onChange(change)}
            value={{
              ...props.value,
              country: props.value.country || 'US',
            }}
            idPrefix="headquarters"
          />
        )}
      </section>
    )
  }),
)
