import React from 'react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, ServerStore, NotificationStore } from '../../../../stores'
import {
  AutoCompleteItem,
  ServerBackedMultiSelectAutoComplete,
} from '../../../custom/ServerBackedMultiSelectAutoComplete'
import { PaginationData, SearchableParams } from '../../../../lib/types/Params'

export interface IBusinessClassifications {
  id: number
  name: string
}

type TProps = {
  serverStore?: ServerStore
  notificationStore?: NotificationStore
  selected: Array<IBusinessClassifications & Partial<AutoCompleteItem>>
  onChange: (nc: Array<IBusinessClassifications & Partial<AutoCompleteItem>>) => void
}

export const BusinessClassifications = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const getBusinessClassifications = (params: SearchableParams) => {
      return props.serverStore!.getBusinessClassifications(params).then((response) => ({
        data: response.data.map((r) => ({ ...r, label: r.name })),
        // This is a hack, since this isn't paginated
        pagination: {
          count: response.data.length,
          page: 1,
          pages: 1,
        } as PaginationData,
      }))
    }

    const getSelectedBusinessClassifications = () => {
      return props.selected.map((nc) => {
        return { ...nc, label: nc.name }
      })
    }

    const selectBusinessClassifications = (
      selected: IBusinessClassifications & AutoCompleteItem,
    ) => {
      if (selected) {
        props.onChange([...props.selected, { ...selected, destroy: false }])
      }
    }

    const unSelect = (business: IBusinessClassifications & AutoCompleteItem) => {
      const item = props.selected.find((bc) => bc.id === business.id)
      if (item && item.destroyable) {
        const unselected = props.selected.map((bc) => {
          if (bc.id === business.id) {
            return { ...bc, destroy: true }
          }
          return bc
        })
        props.onChange(unselected)
      } else {
        props.onChange(props.selected.filter((bc) => bc.id !== business.id))
      }
    }

    const unDestroy = (id: number) => {
      props.onChange(
        props.selected.map((bc) => {
          if (bc.id === id) {
            return { ...bc, destroy: false }
          }
          return bc
        }),
      )
    }

    return (
      <section className="BusinessClassifications" data-testid="BusinessClassifications-section">
        <ServerBackedMultiSelectAutoComplete
          getDataFunction={getBusinessClassifications}
          onSelect={(selected) => {
            selectBusinessClassifications(selected)
          }}
          onRemove={(selected) => {
            unSelect(selected)
          }}
          onUndestroy={(selected) => {
            unDestroy(selected)
          }}
          selections={getSelectedBusinessClassifications()}
          placeholder={'Search and select multiple business classifications'}
          className="business-classifications"
          testId={'-business-classifications-select'}
        />
      </section>
    )
  }),
)
