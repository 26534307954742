import React from 'react'
import { CCard, CCardHeader, CCardBody, CRow, CCol, CFormLabel, CFormInput } from '@coreui/react'
import { ValidatedCFormInput, ValidatedCFormTextArea } from '../../../custom'
import { validateLength } from '../../../../lib/helpers/validation'
import { BusinessClassifications, IBusinessClassifications } from './BusinessClassifications'
import { INaicsCodes, NaicsCodes } from './NaicsCodes'
import { ITechnologyTypes, TechnologyFocusAreas } from './TechnologyFocusAreas'
import { Destructible } from '../../../../stores/ListingStore'

export type ListingContractingDetailsFormData = {
  samCode?: string
  dunsCode?: string
  uniqueEntityId: string
  businessClassifications: Array<IBusinessClassifications & Destructible>
  naicsCodes: Array<INaicsCodes & Destructible>
  technologyTypes: Array<ITechnologyTypes & Destructible>
  capabilityStatement: string
}

type TProps = {
  value: Partial<ListingContractingDetailsFormData>
  onChange: (value: Partial<ListingContractingDetailsFormData>) => void
}

export const ContractingDetails = (props: TProps) => {
  return (
    <section className="ContractingDetails">
      <CCard>
        <CCardHeader>Contracting Details</CCardHeader>
        <CCardBody>
          <CRow className="mb-3 align-items-center">
            <CFormLabel className="col-sm-3 colform-label">Unique Entity ID</CFormLabel>
            <CCol>
              <ValidatedCFormInput
                required
                type="text"
                data-testid="unique-entity-id-input"
                value={props.value.uniqueEntityId || ''}
                placeholder="Unique Entity ID"
                validate={(v) => validateLength(v, 2)}
                onChange={(value: string | undefined) =>
                  props.onChange({ ...props.value, uniqueEntityId: value })
                }
                renderInvalidMessage={() => 'Must be at least two characters'}
              />
            </CCol>
          </CRow>
          <CRow className="mb-2 align-items-center">
            <CFormLabel className="col-sm-2 colform-label">SAM Code</CFormLabel>
            <CCol sm={4}>
              <CFormInput
                type="text"
                data-testid="sam-code-input"
                value={props.value.samCode || ''}
                placeholder="SAM Code"
                onChange={({ target: { value } }) =>
                  props.onChange({ ...props.value, samCode: value })
                }
              />
            </CCol>
            <CFormLabel className="col-sm-2">DUNS Code</CFormLabel>
            <CCol sm={4}>
              <CFormInput
                type="text"
                data-testid="duns-code-input"
                value={props.value.dunsCode || ''}
                placeholder="DUNS Code"
                onChange={({ target: { value } }) =>
                  props.onChange({ ...props.value, dunsCode: value })
                }
              />
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CFormLabel className="colform-label">Business Classifications</CFormLabel>
            <CCol className="dropdown">
              <BusinessClassifications
                selected={props.value.businessClassifications || []}
                onChange={(change) =>
                  props.onChange({ ...props.value, businessClassifications: change })
                }
              />
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CFormLabel className="colform-label">NAICS Codes</CFormLabel>
            <CCol className="dropdown">
              <NaicsCodes
                selected={props.value.naicsCodes || []}
                onChange={(change) => props.onChange({ ...props.value, naicsCodes: change })}
              />
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CFormLabel className="colform-label">Company Focus Areas</CFormLabel>
            <CCol className="dropdown">
              <TechnologyFocusAreas
                selected={props.value.technologyTypes || []}
                onChange={(change) => props.onChange({ ...props.value, technologyTypes: change })}
              />
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CFormLabel>Statement of Capabilities</CFormLabel>
            <CCol>
              <ValidatedCFormTextArea
                type="text"
                placeholder="Statement of Capabilities"
                id="statement-capabilities"
                data-testid={'statement-of-capabilities-input'}
                onChange={(value) => props.onChange({ ...props.value, capabilityStatement: value })}
                validate={(v) => validateLength(v, 50)}
                value={props.value.capabilityStatement}
                renderInvalidMessage={() => 'Must be at least 50 characters'}
                required
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </section>
  )
}
