import { CButton, CButtonGroup } from '@coreui/react'
import React from 'react'
import { ListingData } from '../../stores/ListingStore'
import { ListingStatusAlert } from './ListingStatusAlert'
import { ListingPublishedStatusIndicator } from './ListingPublishedStatusIndicator'
import { ListingPublishOrUnpublishActionButton } from './ListingPublishOrUnpublishActionButton'

type TProps = {
  listing: ListingData
  onAction: () => void
}
export const ListingCard = (props: TProps) => {
  return (
    <div
      className="company-card"
      key={props.listing.id}
      data-testid={`company-card-${props.listing.id}`}
    >
      <div className="company-card-status">
        <ListingPublishedStatusIndicator listing={props.listing} />
        <ListingStatusAlert listing={props.listing} />
      </div>
      <div className="company-card-content">
        <img src={props.listing.logo.path} alt="Company Logo" className="company-logo" />
        <h5 className="bold">{props.listing.name}</h5>
        <div className="company-tagline">
          <p className="company-tagline">{props.listing.tagLine}</p>
        </div>
        <p>Capabilities</p>
        <div className="truncate-overflow-container paragraph">
          <p className="truncate-overflow">{props.listing.capabilityStatement}</p>
        </div>
      </div>
      <div className="company-card-action">
        <CButtonGroup role="group">
          <CButton className="span-btn" href={`/listings/${props.listing.id}`}>
            <i className="far fa-eye" />
            <span>View</span>
          </CButton>
          <CButton className="span-btn" href={`/listings/${props.listing.id}/edit`}>
            <i className="fas fa-pencil-alt" />
            <span>Edit</span>
          </CButton>
          <ListingPublishOrUnpublishActionButton
            listing={props.listing}
            onAction={() => props.onAction()}
            withText
          />
        </CButtonGroup>
      </div>
    </div>
  )
}
