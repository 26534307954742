import React from 'react'
import { PricingItemData } from '../../lib/types'
import { CCard, CCardBody, CCardText, CCardTitle } from '@coreui/react'
import classnames from 'classnames'
import { formatPricingCost, formatPricingPeriod } from '../../lib/helpers/utils'

type TProps = {
  item: PricingItemData
  onSelect: () => void
  isSelected: boolean
}
export const PricingDataOption = (props: TProps) => {
  return (
    <CCard
      className={classnames('PricingDataOption', { selected: props.isSelected })}
      onClick={() => props.onSelect()}
      data-testid={`pricing-option-${props.item.id}`}
    >
      <CCardBody>
        <CCardTitle className="text-center">{formatPricingCost(props.item)}</CCardTitle>
        <CCardText className="text-center">
          <span>{formatPricingPeriod(props.item)}</span>
        </CCardText>
      </CCardBody>
    </CCard>
  )
}
