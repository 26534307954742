import { Link } from 'react-router-dom'
import React from 'react'

export const LegalLinks = () => {
  return (
    <div className="LegalLinks">
      <span>
        <Link to={'/terms'}>Terms</Link>
      </span>
      <span>
        <Link to={'/privacy'}>Privacy</Link>
      </span>
      <span>
        <Link to={'/anti_spam'}>Anti Spam</Link>
      </span>
    </div>
  )
}
