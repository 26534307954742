import { inject, observer } from 'mobx-react'
import { useRouteMatch } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { parseDate, parseDateTime } from '../../lib/time'
import { ApplicationStore, ListingStore, NotificationStore } from '../../stores'
import { ListingData } from '../../stores/ListingStore'
import {
  CCard,
  CCardBody,
  CCardTitle,
  CCardText,
  CContainer,
  CRow,
  CCol,
  CButton,
  CButtonGroup,
  CTooltip,
} from '@coreui/react'
import {
  BusinessClassificationsCard,
  ContractingInformationCard,
  PointOfContactCard,
  SocialMediaDetails,
  TechnologyFocusAreasCard,
  ListingStatusIndicator,
  ListingCancelOrRenewActionButton,
  ListingPublishedStatusIndicator,
  ListingPublishOrUnpublishActionButton,
  ListingPaymentActionButton,
} from '../../components/listing'
import { formatUrlProtocol } from '../../lib/helpers/utils'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'

type TProps = {
  listingStore?: ListingStore
  notificationStore?: NotificationStore
}

interface MatchParams {
  id: string
}

const ViewListing = inject(
  ApplicationStore.names.listingStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [params] = useQueryParams({
      checkoutSuccess: withDefault(StringParam, undefined),
    })

    const {
      params: { id },
    } = useRouteMatch<MatchParams>()
    const [listing, setListing] = useState<ListingData | undefined>(undefined)

    useEffect(() => {
      let basePromise: Promise<ListingData>
      // This handles if we've been redirected here from a stripe checkout session
      if (
        params.checkoutSuccess &&
        ['true', 'false'].includes(params.checkoutSuccess.toLowerCase())
      ) {
        basePromise =
          params.checkoutSuccess === 'true'
            ? props.listingStore!.recordListingCheckoutSessionComplete(id)
            : props.listingStore!.recordListingCheckoutSessionCanceled(id)
      } else {
        basePromise = props.listingStore!.getSingleListing(id)
      }

      basePromise
        .then((listingResponse) => {
          setListing(listingResponse)
        })
        .catch(() => {
          props.notificationStore?.setNotificationMessage(
            'Unable to get listing data; please try again later',
            'danger',
          )
        })
    }, [params])

    if (!listing) {
      return <></>
    }

    return (
      <section className="ViewListing">
        <div className="view-infobar container">
          <div className="view-infobar-status align-middle">
            <ListingStatusIndicator listing={listing} />
            <ListingPublishedStatusIndicator listing={listing} />
          </div>

          <CButtonGroup role="group">
            <ListingPaymentActionButton listing={listing} />
            <ListingCancelOrRenewActionButton
              listing={listing}
              onAction={(data) => setListing(data)}
            />
            <ListingPublishOrUnpublishActionButton
              listing={listing}
              onAction={(data) => setListing(data)}
            />
            <CTooltip content={'Edit this listing'}>
              <CButton className="edit-listing-btn span-btn" href={`/listings/${listing.id}/edit`}>
                <i className="fas fa-pencil-alt" />
              </CButton>
            </CTooltip>
          </CButtonGroup>
        </div>
        <CContainer className="company-details">
          <CCard>
            <CCardBody>
              <CRow className="mb-2">
                <CCol className="listing-element-container">
                  <img src={listing.logo.path} alt="Company Logo" className="listing-logo" />
                </CCol>

                <CCol xs={5}>
                  <h3>{listing.name}</h3>
                  <p className="bold-text">{listing.tagLine}</p>
                  <p>
                    <a href={formatUrlProtocol(listing.website)}>{listing.website}</a>
                  </p>
                </CCol>

                <CCol>
                  <strong>Headquarters</strong>
                  <p>{listing.addressLine1}</p>
                  <p>{listing.addressLine2}</p>
                  <p>{listing.addressLine3}</p>
                  <p>
                    {listing.city}, {listing.stateProvince} {listing.postalCode}
                  </p>
                  <p>{listing.country}</p>
                </CCol>

                <CCol>
                  <SocialMediaDetails path={listing.listingSocialMediaAccounts} />
                </CCol>
              </CRow>

              <CRow>
                <CCol className="listing-element-container" />

                <CCol xs={5}>
                  <p className="small-text">Company Founded Year: {listing.yearFounded}</p>
                  <p className="small-text">
                    Last Profile Sync: {parseDateTime(listing.updatedAt)}
                  </p>
                </CCol>

                <CCol>
                  {['active', 'active_canceled'].includes(listing.subscription.paymentStatus) &&
                    listing.subscription.periodStart &&
                    listing.subscription.periodEnd && (
                      <>
                        <p className="small-text">
                          Payment Period Start: {parseDate(listing.subscription.periodStart)}
                        </p>
                        <p className="small-text">
                          Payment Period End: {parseDate(listing.subscription.periodEnd)}
                        </p>
                      </>
                    )}
                </CCol>

                <CCol>
                  {listing.subscription.cancelAt && (
                    <p className="small-text">
                      Cancel Date: {parseDate(listing.subscription.cancelAt)}
                    </p>
                  )}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CContainer>

        <CContainer>
          <CRow>
            <CCol lg={4}>
              <PointOfContactCard listingId={listing.id} />
              <BusinessClassificationsCard path={listing.businessClassifications} />
              <ContractingInformationCard
                naicsCodesPath={listing.naicsCodes}
                samCode={listing.samCode}
                dunsCode={listing.dunsCode}
                uniqueEntityId={listing.uniqueEntityId}
              />
              <TechnologyFocusAreasCard path={listing.technologyTypes} />
            </CCol>

            <CCol xs={8}>
              <CCard className="listing-card">
                <CCardBody>
                  <CCardTitle className="listing-details bold-text">
                    Statement of Capabilities
                  </CCardTitle>
                  <CCardText className="multiline">{listing.capabilityStatement}</CCardText>
                </CCardBody>
              </CCard>

              {/* temporarily removing these until this functionality is implemented */}
              {/*<ProductsCard />*/}
              {/*<ContractsCard />*/}
            </CCol>
          </CRow>
        </CContainer>
      </section>
    )
  }),
)

export default React.memo(ViewListing)
