import { ApplicationStore, NotificationStore, ServerStore } from '../../stores'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { CBadge, CCard, CCardBody, CCardTitle, CCol, CRow } from '@coreui/react'
import { INaicsCodes } from '../form/fragments/listing'

type TProps = {
  naicsCodesPath: string
  samCode?: string
  dunsCode?: string
  uniqueEntityId: string
  notificationStore?: NotificationStore
  serverStore?: ServerStore
}
export const ContractingInformationCard = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [naicsCodesData, setNaicsCodesData] = useState<Array<INaicsCodes> | undefined>(undefined)
    useEffect(() => {
      props.serverStore
        ?.server()
        .get<Array<INaicsCodes>>(props.naicsCodesPath)
        .then(({ data }) => {
          setNaicsCodesData(data)
        })
        .catch(() => {
          props.notificationStore?.setNotificationMessage(
            'Failed to load listing NAICS code data. Please try again.',
            'danger',
            3000,
          )
        })
    }, [props.naicsCodesPath])

    const makeNaicsCodeLabel = (nc: INaicsCodes) => {
      return `(${nc.code}) - ${nc.name}`
    }
    return (
      <CCard className="listing-card">
        <CCardBody>
          <CCardTitle className="bold-text">Contracting Information</CCardTitle>

          <CRow className="mb-2">
            <CCol>
              <strong>Unique Entity ID</strong> {props.uniqueEntityId}
            </CCol>
          </CRow>
          {props.samCode && (
            <CRow className="mb-2">
              <CCol>
                <strong>SAM Code</strong> {props.samCode}
              </CCol>
            </CRow>
          )}
          {props.dunsCode && (
            <CRow className="mb-2">
              <CCol>
                <strong>DUNS</strong> {props.dunsCode}
              </CCol>
            </CRow>
          )}
          <CRow className="mb-2">
            <CCol>
              <strong>NAICS Codes</strong>
            </CCol>
          </CRow>
          <CRow className="mt-2 justify-content-md-start px-2">
            {naicsCodesData?.map((nc) => {
              const id = `naicsCodes${nc.id}`

              return (
                <CCol key={id} data-testid={id} className={'col-md-auto px-1'}>
                  <CBadge color="primary" className="text-wrap text-md-start">
                    {makeNaicsCodeLabel(nc)}
                  </CBadge>
                </CCol>
              )
            })}
          </CRow>
        </CCardBody>
      </CCard>
    )
  }),
)
