import React, { PropsWithChildren } from 'react'
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'

type ConfirmationBoundaryModalProps = {
  onCancel: () => void
  onConfirm: () => void
  isOpen: boolean
  headerText?: string
  isSuspended?: boolean
  isConfirmDisabled?: boolean
}
export const ConfirmationBoundaryModal = (
  props: PropsWithChildren<ConfirmationBoundaryModalProps>,
) => {
  return (
    <CModal size="lg" alignment="center" visible={props.isOpen} onClose={props.onCancel}>
      <section
        data-testid="are-you-sure-modal-content"
        className="confirmation-boundary-modal-content"
      >
        <CModalHeader>
          <CModalTitle className="text-center">{props.headerText || 'Are you sure?'} </CModalTitle>
        </CModalHeader>
        <CModalBody>{props.children}</CModalBody>
        <CModalFooter>
          <CButton
            color="primary"
            onClick={() => props.onConfirm()}
            disabled={props.isSuspended || props.isConfirmDisabled}
            data-testid="are-you-sure-confirm-btn"
          >
            {props.isSuspended ? (
              <i data-testid="suspense-spinner" className="fa fa-spinner fa-spin" />
            ) : (
              <i className="fas fa-check" />
            )}
            Confirm
          </CButton>
          <CButton
            color="secondary"
            onClick={() => props.onCancel()}
            disabled={props.isSuspended}
            data-testid={'are-you-sure-cancel-btn'}
            className="cancel-button"
          >
            Cancel
          </CButton>
        </CModalFooter>
      </section>
    </CModal>
  )
}
