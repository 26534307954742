import React, { useState } from 'react'
import { ListingData, ListingStore } from '../../stores/ListingStore'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, NotificationStore } from '../../stores'
import { CButton, CTooltip } from '@coreui/react'
import { parseDate } from '../../lib/time'
import { ConfirmationBoundaryModal } from '../custom'
import { canListingCancel, canListingRenew } from '../../lib/listing/utils'

type TProps = {
  listing: ListingData
  listingStore?: ListingStore
  notificationStore?: NotificationStore
  onAction: (data: ListingData) => void
}
export const ListingCancelOrRenewActionButton = inject(
  ApplicationStore.names.listingStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [isCancelPromptOpen, setIsCancelPromptOpen] = useState<boolean>(false)
    const [isCancelSuspending, setIsCancelSuspending] = useState<boolean>(false)
    const [isRenewPromptOpen, setIsRenewPromptOpen] = useState<boolean>(false)
    const [isRenewSuspending, setIsRenewSuspending] = useState<boolean>(false)

    const onCancelConfirm = () => {
      setIsCancelSuspending(true)
      props.listingStore
        ?.cancelListing(props.listing.id)
        .then((data) => {
          setIsCancelSuspending(false)
          setIsCancelPromptOpen(false)
          props.notificationStore?.setNotificationMessage(
            `Successfully canceled subscription. Cancelation will take effect : ${parseDate(
              data.subscription.cancelAt!,
            )}`,
            'success',
            3000,
          )
          props.onAction(data)
        })
        .catch(() => {
          setIsCancelSuspending(false)
          props.notificationStore?.setNotificationMessage(
            'Failed to cancel listing. Please try again',
            'danger',
            3000,
          )
        })
    }

    const onRenewConfirm = () => {
      // TODO this won't work if it's past the cancelAt date
      setIsRenewSuspending(true)
      props.listingStore
        ?.renewListing(props.listing.id)
        .then((data) => {
          setIsRenewSuspending(false)
          setIsRenewPromptOpen(false)
          props.notificationStore?.setNotificationMessage(
            `Successfully renewed subscription`,
            'success',
            3000,
          )
          props.onAction(data)
        })
        .catch(() => {
          setIsRenewSuspending(false)
          props.notificationStore?.setNotificationMessage(
            'Failed to renew listing. Please try again',
            'danger',
            3000,
          )
        })
    }

    const renderCancelActionButton = () => {
      const isDisabled = !canListingCancel(props.listing)
      return (
        <>
          <CTooltip content={'Cancel this listing'}>
            <CButton
              className="span-btn publish-action-btn"
              data-testid="listing-cancel-btn"
              onClick={() => setIsCancelPromptOpen(true)}
              disabled={isDisabled}
            >
              <i className="fas fa-ban" />
            </CButton>
          </CTooltip>
          <ConfirmationBoundaryModal
            onCancel={() => setIsCancelPromptOpen(false)}
            onConfirm={onCancelConfirm}
            isOpen={isCancelPromptOpen}
            headerText={'Are you sure you want to cancel this listing?'}
            isSuspended={isCancelSuspending}
          >
            <p>
              The listing will remain publishable until the end of your current billing period (
              {parseDate(props.listing.subscription.periodEnd)}) at which point you will no longer
              be charged and this listing will no longer be visible.
              <br />
              You can renew this listing until the aforementioned date.
            </p>
          </ConfirmationBoundaryModal>
        </>
      )
    }

    const renderRenewCancelActionButton = () => {
      const isDisabled = !canListingRenew(props.listing)

      return (
        <>
          <CTooltip content={'Renew this listing'}>
            <CButton
              color="info"
              className="span-btn publish-action-btn"
              data-testid="listing-renew-btn"
              onClick={() => setIsRenewPromptOpen(true)}
              disabled={isDisabled}
            >
              <i className="fas fa-undo" />
            </CButton>
          </CTooltip>
          <ConfirmationBoundaryModal
            onCancel={() => setIsRenewPromptOpen(false)}
            onConfirm={onRenewConfirm}
            isOpen={isRenewPromptOpen}
            headerText={'Are you sure you want to renew this listing?'}
            isSuspended={isRenewSuspending}
          >
            <p>
              By renewing this listing, you will be charged the listing price again at the next
              billing date ({parseDate(props.listing.subscription.periodEnd)}). You can cancel again
              at any time.
            </p>
          </ConfirmationBoundaryModal>
        </>
      )
    }

    return props.listing.subscription.cancelAtPeriodEnd
      ? renderRenewCancelActionButton()
      : renderCancelActionButton()
  }),
)
