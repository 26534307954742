import React, { FormEvent, useState } from 'react'
import { ApplicationStore, AuthStore, NotificationStore, UserStore } from '../../stores'
import { inject, observer } from 'mobx-react'
import { SignupAccountData, SignupAccountForm } from './fragments'
import { CButton, CCol, CForm, CRow } from '@coreui/react'
import { validateLength } from '../../lib/helpers/validation'

type TProps = {
  userStore?: UserStore
  notificationStore?: NotificationStore
  authStore?: AuthStore
  onSubmitted?: () => void
}

export const EditAccountForm = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.authStore,
)(
  observer((props: TProps) => {
    const [formData, setFormData] = useState<Partial<SignupAccountData>>({
      name: props.userStore?.currentAccountData?.name,
      logo: props.userStore?.currentAccountData?.logo.path,
    })
    const [hasValidated, setHasValidated] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const isSubmittable = (): boolean => {
      let nameError = validateLength(formData.name, 2)

      if (nameError) {
        return false
      }

      return [formData.name, formData.logo].every((e) => !!e)
    }

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      setHasValidated(true)
      setIsSubmitting(true)
      props.userStore
        ?.updateAccount({
          name: formData.name!,
          logo: formData.logo instanceof File ? formData.logo : undefined,
        })
        .then(() => {
          setTimeout(() => {
            // auth store salt to reload data
            setIsSubmitting(false)
            props.authStore?.generateAndSetSalt()
            props.notificationStore?.setNotificationMessage(
              'Account updated successfully.',
              'success',
              3000,
            )
            props.onSubmitted && props.onSubmitted()
          }, 500)
        })
        .catch(() => {
          setIsSubmitting(false)
          props.notificationStore?.setNotificationMessage(
            'Failed to update account, please try again.',
            'danger',
            3000,
          )
        })
    }

    return (
      <section className="EditAccountForm EditProfileForm" data-testid="edit-account-form">
        <CForm
          onSubmit={onSubmit}
          validated={hasValidated}
          className="needs-validation"
          {...{ noValidate: true }}
        >
          <SignupAccountForm value={formData} onChange={(v) => setFormData(v)} />

          <CRow className="action-row justify-content-end">
            <CCol sm={3} className="text-end">
              <CButton
                type="submit"
                data-testid="save-button"
                disabled={!isSubmittable() || isSubmitting}
              >
                {isSubmitting ? (
                  <i data-testid="suspense-spinner" className="fa fa-spinner fa-spin" />
                ) : (
                  <i className="fas fa-save" />
                )}
                Save
              </CButton>
            </CCol>
          </CRow>
        </CForm>
      </section>
    )
  }),
)
