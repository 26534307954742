import React, { useEffect, useState } from 'react'
import { PricingItemData } from '../../lib/types'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, NotificationStore } from '../../stores'
import { PricingDataOption } from './PricingDataOption'
import { CCol, CRow } from '@coreui/react'
import { Spinner } from '../custom'
import classnames from 'classnames'

type TProps = {
  getPricingData: () => Promise<Array<PricingItemData>>
  onSelect: (value: PricingItemData | undefined) => void
  selected?: PricingItemData
  notificationStore?: NotificationStore
}
export const PricingDataSelector = inject(ApplicationStore.names.notificationStore)(
  observer((props: TProps) => {
    const [pricingData, setPricingData] = useState<Array<PricingItemData> | undefined>(undefined)

    useEffect(() => {
      props
        .getPricingData()
        .then((data) => {
          setTimeout(() => setPricingData(data), 300)
        })
        .catch(() => {
          props.notificationStore?.setNotificationMessage(
            'Failed to retrieve pricing data. Please try again',
            'danger',
            3000,
          )
        })
    }, [])

    const renderSuspense = () => {
      return (
        <CRow className="suspense-content">
          <CCol className="d-flex justify-content-center align-items-center">
            <Spinner className="spinner" />
          </CCol>
        </CRow>
      )
    }

    const renderSelector = () => {
      return (
        <>
          <CRow className="mb-1">
            <CCol>
              <div className="pricing-info-prompt subtle">
                <i
                  className={classnames('fas fa-asterisk required-icon', {
                    valid: !!props.selected,
                  })}
                  data-testid={'test-required-icon'}
                />
                Choose a pricing option:
              </div>
            </CCol>
          </CRow>
          <CRow className="mb-1">
            {pricingData?.map((p) => (
              <CCol key={p.id}>
                <PricingDataOption
                  item={p}
                  onSelect={() => props.onSelect(p)}
                  isSelected={props.selected?.id === p.id}
                />
              </CCol>
            ))}
          </CRow>
        </>
      )
    }
    return (
      <section className="PricingDataSelector">
        {pricingData ? renderSelector() : renderSuspense()}
      </section>
    )
  }),
)
