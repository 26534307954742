import React from 'react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, ServerStore, NotificationStore } from '../../../../stores'
import {
  AutoCompleteItem,
  ServerBackedMultiSelectAutoComplete,
} from '../../../custom/ServerBackedMultiSelectAutoComplete'
import { PaginatedSearchableParams, ServerPageData } from '../../../../lib/types/Params'

export interface INaicsCodes {
  id: number
  name: string
  code: string
}

type TProps = {
  serverStore?: ServerStore
  notificationStore?: NotificationStore
  selected: Array<INaicsCodes & Partial<AutoCompleteItem>>
  onChange: (nc: Array<INaicsCodes & Partial<AutoCompleteItem>>) => void
}

export const NaicsCodes = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const getNaicsCodes = (
      params: PaginatedSearchableParams,
    ): Promise<ServerPageData<INaicsCodes & AutoCompleteItem>> => {
      return props.serverStore!.getNaicsCodes(params).then((response) => ({
        pagination: response.pagination,
        data: response.data.map((r) => ({ ...r, label: `(${r.code}) - ${r.name}` })),
      }))
    }

    const getSelectedNaicsCodes = () => {
      return props.selected.map((nc) => {
        return { ...nc, label: nc.name }
      })
    }

    const selectNaicsCode = (selected: INaicsCodes & AutoCompleteItem) => {
      if (selected) {
        props.onChange([...props.selected, { ...selected, destroy: false }])
      }
    }

    const unSelect = (code: INaicsCodes & AutoCompleteItem) => {
      const item = props.selected.find((nc) => nc.id === code.id)
      if (item && item.destroyable) {
        props.onChange(
          props.selected.map((nc) => {
            if (nc.id === code.id) {
              return { ...nc, destroy: true }
            }
            return nc
          }),
        )
      } else {
        props.onChange(props.selected.filter((nc) => nc.id !== code.id))
      }
    }

    const unDestroy = (codeId: number) => {
      props.onChange(
        props.selected.map((nc) => {
          if (nc.id === codeId) {
            return { ...nc, destroy: false }
          }
          return nc
        }),
      )
    }

    return (
      <section className="NaicsCodes">
        <ServerBackedMultiSelectAutoComplete
          getDataFunction={getNaicsCodes}
          onSelect={(selected) => {
            selectNaicsCode(selected)
          }}
          onRemove={(selected) => {
            unSelect(selected)
          }}
          onUndestroy={(selected) => {
            unDestroy(selected)
          }}
          selections={getSelectedNaicsCodes()}
          placeholder={'Search and select multiple NAICS codes'}
          className="naics-codes"
          testId={'-naics-codes-select'}
        />
      </section>
    )
  }),
)
