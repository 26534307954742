import React, { useState } from 'react'
import { FileUploader } from './FileUploader'
import { ImageCroppingTool } from './ImageCroppingTool'
import { CButton } from '@coreui/react'
import classnames from 'classnames'

export type TProps = {
  onFile: (file: File | undefined) => void
  value: File | string | undefined
  required?: boolean
  'data-testid'?: string
  hideUploaderPlaceholder?: boolean
}
enum Mode {
  UPLOAD,
  EDIT,
  DISPLAY,
}
export const SingleImageUploader = (props: TProps) => {
  const [mode, setMode] = useState<Mode>(props.value ? Mode.DISPLAY : Mode.UPLOAD)
  const [value, setValue] = useState<File | string | undefined>(props.value)

  const renderUploader = () => {
    return (
      <FileUploader
        onDrop={(acceptedFiles) => {
          setValue(acceptedFiles[0])
          setMode(Mode.EDIT)
        }}
        text="Drop image file or click to upload"
        accept={['image/jpg', 'image/jpeg', 'image/png']}
        hidePlaceholder={props.hideUploaderPlaceholder}
      />
    )
  }

  const getBlobURL = React.useMemo(() => {
    return value && value instanceof File ? URL.createObjectURL(value) : value
  }, [value])

  const renderImageCroppingTool = () => {
    return (
      <ImageCroppingTool
        image={value!}
        onCrop={(value: File) => {
          setValue(value)
          props.onFile(value)
          setMode(Mode.DISPLAY)
        }}
      />
    )
  }

  const renderDisplay = () => {
    return (
      <div className="ImageDisplay">
        <img
          id="SingleImageUploader-display-image"
          data-testid="SingleImageUploader-display-image"
          src={getBlobURL}
          alt="Display"
        />
      </div>
    )
  }

  const render = () => {
    switch (mode) {
      case Mode.UPLOAD:
        return renderUploader()
      case Mode.EDIT:
        return renderImageCroppingTool()
      case Mode.DISPLAY:
        return renderDisplay()
    }
  }

  return (
    <section
      className={classnames('SingleImageUploader', {
        ValidatedInput: props.required,
        'form-control': props.required,
        'is-valid': props.required && props.value && mode === Mode.DISPLAY,
      })}
      data-testid={props['data-testid']}
    >
      {props.required && (
        <i
          className={classnames('fas fa-asterisk required-icon', {
            valid: !!props.value,
          })}
        />
      )}
      {render()}
      <div className="image-tool-container d-flex">
        <CButton
          data-testid="select-upload-mode"
          className="icon-button"
          onClick={() => {
            setMode(Mode.UPLOAD)
            props.onFile(undefined)
          }}
        >
          <i className={classnames('fas fa-file-upload', { isActive: mode === Mode.UPLOAD })} />
        </CButton>

        <div className={classnames('clickable', { isInActive: !value })}>
          <CButton
            data-testid="select-edit-mode"
            className="icon-button"
            onClick={() => {
              setMode(Mode.EDIT)
              props.onFile(undefined)
            }}
          >
            <i
              className={classnames('fas fa-crop', {
                isActive: mode === Mode.EDIT,
              })}
            />
          </CButton>
        </div>

        <div className={classnames('clickable', { isInActive: !props.value })}>
          <CButton
            data-testid="select-display-mode"
            className="icon-button"
            onClick={() => setMode(Mode.DISPLAY)}
          >
            <i
              className={classnames('far fa-image', {
                isActive: mode === Mode.DISPLAY,
              })}
            />
          </CButton>
        </div>
      </div>
    </section>
  )
}
