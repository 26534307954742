import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCol, CContainer, CRow } from '@coreui/react'
import { AccountStore, ApplicationStore, AuthStore, NotificationStore } from '../stores'
import { inject, observer } from 'mobx-react'
import { TermsOrPrivacyResponse } from '../stores/AccountStore'
import { Redirect } from 'react-router-dom'
import { parseDate } from '../lib/time'
import ReactHtmlParser from 'react-html-parser'

type TProps = {
  accountStore?: AccountStore
  notificationStore?: NotificationStore
  authStore?: AuthStore
}
export const SignTermsAndPrivacy = inject(
  ApplicationStore.names.accountStore,
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.authStore,
)(
  observer((props: TProps) => {
    const [term, setTerm] = useState<TermsOrPrivacyResponse | undefined>()
    const [hasSignedTerm, setHasSignedTerm] = useState<boolean>(false)
    const [privacy, setPrivacy] = useState<TermsOrPrivacyResponse | undefined>()
    const [hasSignedPrivacy, setHasSignedPrivacy] = useState<boolean>(false)
    const [isAgreeSuspended, setIsAgreeSuspended] = useState<boolean>(false)

    useEffect(() => {
      Promise.all([props.accountStore!.getLatestTerms(), props.accountStore!.getLatestPrivacy()])
        .then(([t, p]) => {
          setTerm(t)
          setPrivacy(p)
        })
        .catch(() => {
          props.notificationStore?.setNotificationMessage(
            'Failed to load terms and privacy data. Please try again.',
            'danger',
            3000,
          )
        })
    }, [])

    useEffect(() => {
      if (hasSignedPrivacy && hasSignedTerm) {
        props.authStore!.generateAndSetSalt()
      }
    }, [hasSignedPrivacy, hasSignedTerm])

    const signTerm = () => {
      return props.accountStore!.signTerms(term!.id).then(() => {
        setTimeout(() => {
          setHasSignedTerm(true)
          setIsAgreeSuspended(false)
        }, 1000)
      })
    }
    const signPrivacy = () => {
      return props.accountStore!.signPrivacy(privacy!.id).then(() => {
        setTimeout(() => {
          setHasSignedTerm(true)
          setHasSignedPrivacy(true)
        }, 1000)
      })
    }
    const onAgree = (key: string) => {
      setIsAgreeSuspended(true)

      const signer = key === 'term' ? signTerm : signPrivacy

      return signer().catch(() => {
        setIsAgreeSuspended(false)
        props.notificationStore?.setNotificationMessage(
          `Failed to sign ${key}. Please try again`,
          'danger',
          3000,
        )
      })
    }

    const renderSignerFor = (key: string, obj: TermsOrPrivacyResponse) => {
      return (
        <div key={`${key}-${obj.id}`} className="terms-privacy-signer">
          <CRow>
            <CCol>
              <h1 className="text-center">{obj.title}</h1>
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CCol>
              <div className="text-center subtle">
                <strong>Effective Date: {parseDate(obj.publishDate)}</strong>
              </div>
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CCol>
              <div className={'content-container-wrapper p-3'}>{ReactHtmlParser(obj.content)}</div>
            </CCol>
          </CRow>
          <CRow className="justify-content-md-center">
            <CCol className="d-flex justify-content-center">
              <CButton
                data-testid="agree-button"
                disabled={isAgreeSuspended}
                onClick={() => onAgree(key)}
              >
                {isAgreeSuspended ? (
                  <i data-testid="agree-suspense-spinner" className="fa fa-spinner fa-spin" />
                ) : (
                  <i className="fas fa-check" />
                )}
                Agree
              </CButton>
            </CCol>
          </CRow>
        </div>
      )
    }

    const determineRender = () => {
      if (!term || !privacy) {
        return <span data-testid="tandpsuspense" />
      }

      if (!hasSignedTerm) {
        return renderSignerFor('term', term)
      }
      if (!hasSignedPrivacy) {
        return renderSignerFor('privacy', privacy)
      }

      return <Redirect to={'/'} />
    }

    return (
      <section className="TermsAndPrivacy bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCard>
              <CCardBody>{determineRender()}</CCardBody>
            </CCard>
          </CRow>
        </CContainer>
      </section>
    )
  }),
)

export default SignTermsAndPrivacy
