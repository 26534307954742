import { ListingData, ListingSubscriptionPaymentStatus } from '../../stores/ListingStore'

export const canListingCancel = (listing: ListingData) => {
  return [ListingSubscriptionPaymentStatus.active].includes(listing.subscription.paymentStatus)
}

export const canListingRenew = (listing: ListingData) => {
  return [ListingSubscriptionPaymentStatus.active_canceled].includes(
    listing.subscription.paymentStatus,
  )
}

export const canListingPublish = (listing: ListingData) => {
  return (
    [
      ListingSubscriptionPaymentStatus.active,
      ListingSubscriptionPaymentStatus.active_canceled,
      ListingSubscriptionPaymentStatus.trialing,
    ].includes(listing.subscription.paymentStatus) &&
    !listing.isPublished &&
    (listing.subscription.cancelAt == null ||
      new Date(listing.subscription.cancelAt).getTime() > new Date().getTime())
  )
}

export const canListingUnpublish = (listing: ListingData) => {
  return listing.isPublished
}

export const canListingTakePaymentAction = (listing: ListingData) => {
  return [ListingSubscriptionPaymentStatus.checkout_canceled].includes(
    listing.subscription.paymentStatus,
  )
}
