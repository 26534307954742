import React, { FormEvent, useState } from 'react'
import { ApplicationStore, AuthStore, NotificationStore, UserStore } from '../../stores'
import { inject, observer } from 'mobx-react'
import { SignupUserData, SignupUserForm } from './fragments'
import { CButton, CCol, CForm, CRow } from '@coreui/react'
import { getTimezoneOptions, parseTimezone } from '../../lib/time'
import { validateLength } from '../../lib/helpers/validation'
type TProps = {
  userStore?: UserStore
  notificationStore?: NotificationStore
  authStore?: AuthStore
  onSubmitted?: () => void
}

export const EditProfileForm = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.authStore,
)(
  observer((props: TProps) => {
    const [formData, setFormData] = useState<Partial<SignupUserData>>({
      firstName: props.userStore?.currentUserData?.firstName,
      lastName: props.userStore?.currentUserData?.lastName,
      title: props.userStore?.currentUserData?.title,
      cellPhone: props.userStore?.currentUserData?.cellPhone,
      workPhone: props.userStore?.currentUserData?.workPhone,
      workPhoneExt: props.userStore?.currentUserData?.workPhoneExt,
      timezone: parseTimezone(
        props.userStore!.currentUserData!.timezone,
        getTimezoneOptions('original'),
      ),
      headshot: props.userStore?.currentUserData?.headshot.path,
      socialMedia: props.userStore?.currentUserData?.socialMediaAccounts,
    })
    const [hasValidated, setHasValidated] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const isSubmittable = (): boolean => {
      let errors = [
        validateLength(formData.firstName, 2),
        validateLength(formData.lastName, 2),
        validateLength(formData.title, 2),
        // TODO: Validate phone
        validateLength(formData.cellPhone, 2),
      ]

      formData.socialMedia &&
        formData.socialMedia.forEach((socialAccount) => {
          if (socialAccount.identifier.length === 1) {
            errors.push(validateLength(socialAccount.identifier, 2))
          }
        })

      if (errors.some((err) => !!err)) {
        return false
      }

      return [formData.headshot, formData.timezone].every((e) => !!e)
    }

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      setHasValidated(true)
      setIsSubmitting(true)
      props.userStore
        ?.updateUser({
          firstName: formData.firstName!,
          lastName: formData.lastName!,
          title: formData.title!,
          cellPhone: formData.cellPhone!,
          workPhone: formData.workPhone,
          workPhoneExt: formData.workPhoneExt,
          timezone:
            typeof formData.timezone === 'string' ? formData.timezone : formData.timezone!.value,
          headshot: formData.headshot instanceof File ? formData.headshot : undefined,
          // TODO destroy semantics,
          socialMedia: formData.socialMedia?.map((sm) => {
            return {
              ...sm,
              destroy: (sm as any).id && !sm.identifier ? true : undefined,
            }
          }),
        })
        .then(() => {
          setTimeout(() => {
            // auth store salt to reload data
            setIsSubmitting(false)
            props.authStore?.generateAndSetSalt()
            props.notificationStore?.setNotificationMessage(
              'Profile updated successfully.',
              'success',
              3000,
            )
            props.onSubmitted && props.onSubmitted()
          }, 500)
        })
        .catch((err) => {
          setIsSubmitting(false)
          props.notificationStore?.setNotificationMessage(
            'Failed to update profile, please try again.',
            'danger',
            3000,
          )
        })
    }

    return (
      <section className="EditProfileForm" data-testid="edit-profile-form">
        <CForm
          onSubmit={onSubmit}
          validated={hasValidated}
          className="needs-validation"
          {...{ noValidate: true }}
        >
          <SignupUserForm value={formData} onChange={(v) => setFormData(v)} />

          <CRow className="action-row justify-content-end">
            <CCol sm={3} className="text-end">
              <CButton
                type="submit"
                data-testid="save-button"
                disabled={!isSubmittable() || isSubmitting}
              >
                {isSubmitting ? (
                  <i data-testid="suspense-spinner" className="fa fa-spinner fa-spin" />
                ) : (
                  <i className="fas fa-save" />
                )}
                Save
              </CButton>
            </CCol>
          </CRow>
        </CForm>
      </section>
    )
  }),
)
