import React from 'react'
import Dropzone, { DropEvent, DropzoneProps, FileRejection } from 'react-dropzone'
import { useState } from 'react'
import { cloudUploadIcon } from '../icons/cloudUploadIcon'
import { Alert } from './Alert'

type TProps = DropzoneProps & {
  onCancel?: () => void
  additionalText?: string
  header?: string
  text?: string
  subText?: string
  maxSize?: number
  hidePlaceholder?: boolean
}

export const FileUploader = (props: TProps) => {
  const [alert, setAlert] = useState<string | undefined>(undefined)
  const maxSize = 10000000 // bytes equivalent of 10 MB

  return (
    <div className="FileUploader">
      {props.header && <h6>{props.header}</h6>}

      <Dropzone
        {...props}
        onDropRejected={(fileRejections: FileRejection[], event: DropEvent) => {
          if (fileRejections[0].file.size > maxSize) {
            setAlert('File larger than 10 MB')
          } else if (props.maxSize && fileRejections[0].file.size > props.maxSize) {
            setAlert(`File larger than ${props.maxSize / 1000000} MB`)
          } else {
            setAlert(fileRejections[0].errors[0].message)
          }
          props.onDropRejected && props.onDropRejected(fileRejections, event)
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div data-testid="dropzone-container" className="dropzone-container" {...getRootProps()}>
            {cloudUploadIcon()}
            {!props.hidePlaceholder && (
              <p className="dropzone-placeholder">
                {props.text || 'Drop file(s) here or click to upload'}
              </p>
            )}

            {props.subText && <span className="sub-text">{props.subText}</span>}
            {props.additionalText && (
              <span className="additional-text">{props.additionalText}</span>
            )}
            <input {...getInputProps()} />
          </div>
        )}
      </Dropzone>

      {props.onCancel && (
        <div className="cancel-container">
          <i
            tabIndex={0}
            data-testid="cancel-icon"
            className="fa fa-times"
            onClick={props.onCancel}
            onKeyDown={({ key: kyprss }) => {
              if (kyprss === 'Enter') {
                props.onCancel!()
              }
            }}
          />
        </div>
      )}

      {alert && (
        <Alert
          message={
            alert.includes('File type must be one of') && alert.length > 75
              ? 'Invalid file type'
              : alert
          }
          onToggleAlert={() => setAlert(undefined)}
          color="warning"
        />
      )}
    </div>
  )
}
