import React from 'react'
import { ListingData } from '../../stores/ListingStore'
import { CBadge } from '@coreui/react'
import { parseDate } from '../../lib/time'
import { capitalizeFirstLetter } from '../../lib/helpers/utils'

type TProps = {
  listing: ListingData
}
export const ListingStatusIndicator = (props: TProps) => {
  const determineIndicatorData = () => {
    /*
      See: https://stripe.com/docs/api/subscriptions/object?lang=ruby#subscription_object-status
      STRIPE STATUSES:
      active
      past_due
      unpaid
      canceled
      incomplete
      incomplete_expired
      trialing

      Our custom:
      active_canceled
      checkout_canceled
      awaiting_checkout
      awaiting_payment_confirmation
     */
    switch (props.listing.subscription.paymentStatus) {
      case 'unpaid':
      case 'incomplete':
      case 'incomplete_expired':
      case 'checkout_canceled':
      case 'past_due':
        return {
          color: 'danger',
          text: `${props.listing.subscription.paymentStatus
            .split('_')
            .map(capitalizeFirstLetter)
            .join(' ')}`,
        }
      case 'awaiting_checkout':
      case 'awaiting_payment_confirmation':
        return {
          color: 'warning',
          text: `${props.listing.subscription.paymentStatus
            .split('_')
            .map(capitalizeFirstLetter)
            .join(' ')}`,
        }
      case 'canceled':
        return {
          color: 'secondary',
          text: `Canceled on ${parseDate(props.listing.subscription.cancelAt!)}`,
        }
      case 'active_canceled':
        return {
          color: 'secondary',
          text: `Canceling on ${parseDate(props.listing.subscription.cancelAt!)}`,
        }
      case 'active':
        return { color: 'primary', text: 'Active' }
      default:
        return { color: 'info', text: props.listing.subscription.paymentStatus }
    }
  }

  const indicatorData = determineIndicatorData()
  return (
    <CBadge color={indicatorData?.color} className="listings-status-indicator align-middle">
      {indicatorData.text}
    </CBadge>
  )
}
