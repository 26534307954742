import React, { useEffect } from 'react'
import { getTimezoneOptions, i18nTimezones, ITimezone, parseTimezone } from '../../lib/time'
import { CFormSelectProps } from '@coreui/react/dist/components/form/CFormSelect'
import { ValidatedCFormSelect } from './ValidatedCFormSelect'

export type ILabelStyle = 'original' | 'altName' | 'abbrev'

type TProps = {
  value?: ITimezone
  onSelect: (option: ITimezone | undefined) => void
  placeholder?: string
  labelStyle?: ILabelStyle
} & Omit<CFormSelectProps, 'value' | 'onSelect'>

export const TimezoneSelect = (props: TProps) => {
  const timezones = i18nTimezones

  const { value, onSelect, placeholder, labelStyle, ...rest } = props

  useEffect(() => {
    if (!props.value) {
      const defaultTz = parseTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone, getOptions)
      defaultTz && props.onSelect(defaultTz)
    }
  }, [props.value])

  const getOptions = React.useMemo(() => {
    return getTimezoneOptions(labelStyle)
  }, [labelStyle, timezones])

  const validate = (v: string) => {
    if (rest.required) {
      return v ? undefined : 'required'
    }
  }

  return (
    <section className="TimezoneSelect">
      <ValidatedCFormSelect
        {...rest}
        aria-label="timezone select"
        onChange={(v) => {
          props.onSelect(parseTimezone(v!, getOptions))
        }}
        value={(!!props.value && parseTimezone(props.value, getOptions)?.value) || ''}
        validate={validate}
      >
        {getOptions.map((o) => {
          return (
            <option
              key={o.value}
              value={o.value}
              data-testid={`${
                (rest as any)['data-testid'] ? (rest as any)['data-testid'] + '-' : ''
              }option-${o.value}`}
            >
              {o.label}
            </option>
          )
        })}
      </ValidatedCFormSelect>
    </section>
  )
}
