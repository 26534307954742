import React, { useEffect, useState } from 'react'
import {
  ApplicationStore,
  ServerStore,
  NotificationStore,
  SocialMediaTypeResponse,
} from '../../../stores'
import { CCol, CInputGroupText, CInputGroup } from '@coreui/react'
import { ValidatedCFormInput } from '../../custom'
import { inject, observer } from 'mobx-react'
import { determineSocialMediaIconClassName } from '../../../lib/helpers/utils'

export type SocialMediaFormData = {
  id?: number
  socialMediaTypeId: number
  identifier: string
}
type TProps = {
  value: Array<SocialMediaFormData>
  onChange: (value: Array<SocialMediaFormData>) => void
  serverStore?: ServerStore
  notificationStore?: NotificationStore
}

export const SocialMediaAccountsForm = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [socialMediaData, setSocialMediaData] = useState<
      Array<SocialMediaTypeResponse> | undefined
    >(undefined)

    useEffect(() => {
      props.serverStore
        ?.getSocialMediaTypes()
        .then((data) => setSocialMediaData(data))
        .catch(() =>
          props.notificationStore?.setNotificationMessage(
            'Failed to retrieve social media data. Please try again',
            'error',
            3000,
          ),
        )
    }, [])

    const onSocialMediaChange = (change: SocialMediaFormData) => {
      const existing = props.value.find((sm) => sm.socialMediaTypeId === change.socialMediaTypeId)
      if (existing) {
        props.onChange(
          props.value.map((sm) => {
            if (sm.socialMediaTypeId === change.socialMediaTypeId) {
              return { ...sm, ...change }
            }
            return sm
          }),
        )
      } else {
        props.onChange([...(props.value || []), change])
      }
    }

    const validateLength = (value: string | undefined, min: number): string | undefined => {
      if (!value || (value && value.length >= min)) {
        return
      }

      return 'insufficient_length'
    }

    return (
      <React.Fragment>
        {socialMediaData?.map((smt) => {
          const id = `socialMedia${smt.name.replace(' ', '')}`
          const existing = props.value.find((sm) => sm.socialMediaTypeId === smt.id)

          return (
            <CCol key={smt.id} className="sm-input-group">
              <CInputGroup>
                <CInputGroupText id="basic-addon1">
                  <i className={determineSocialMediaIconClassName(smt.name)} />
                </CInputGroupText>
                <ValidatedCFormInput
                  type="text"
                  id={id}
                  data-testid={id}
                  placeholder={smt.name}
                  value={existing?.identifier || ''}
                  onChange={(value) =>
                    onSocialMediaChange({
                      socialMediaTypeId: smt.id,
                      identifier: value ? value : '',
                    })
                  }
                  validate={(value) => validateLength(value, 2)}
                  renderInvalidMessage={() => 'Must be at least 2 characters'}
                />
              </CInputGroup>
            </CCol>
          )
        })}
      </React.Fragment>
    )
  }),
)
