import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import { Point, Area } from 'react-easy-crop/types'
import { ApplicationStore, NotificationStore } from '../../stores'
import { inject, observer } from 'mobx-react'
import { CButton } from '@coreui/react'

type TProps = {
  image: File | string
  onCrop: (file: File) => void
  notificationStore?: NotificationStore
}

export const ImageCroppingTool = inject(ApplicationStore.names.notificationStore)(
  observer((props: TProps) => {
    const [croppedPixels, onCropPixels] = useState<Area | undefined>(undefined)
    const [zoom, onZoomChange] = useState<number>(1)
    const [rotation, onRotationChange] = useState<number>(0)
    const [crop, onCropChange] = useState<Point>({ x: 0, y: 0 })

    const getBlobURL = React.useMemo(() => {
      return props.image && props.image instanceof File
        ? URL.createObjectURL(props.image)
        : props.image
    }, [props.image])

    const blobToFile = (blob: Blob) => {
      return new File([blob], 'image.jpg', {
        type: 'image/jpeg',
      })
    }

    const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
      onCropPixels(croppedAreaPixels)
    }, [])

    const createImage = async (url: string) => {
      const imagePromise: Promise<HTMLImageElement | undefined> = new Promise((resolve, reject) => {
        const newImage = new Image()
        newImage.src = url
        newImage.onload = () => resolve(newImage)
        newImage.onerror = () => {
          handleRejection()
          reject('Error loading image')
        }
      })
      return imagePromise
    }

    const handleRejection = () => {
      return props.notificationStore?.setNotificationMessage(
        'Something went wrong while processing image, please try again later',
        'error',
        3000,
      )
    }

    const getCroppedImageBlob = async (
      flip = { horizontal: false, vertical: false },
    ): Promise<Blob | null> => {
      const image = await createImage(getBlobURL)
      const canvas: HTMLCanvasElement = document.createElement('canvas')
      const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d')

      if (!ctx || !croppedPixels) {
        return null
      }

      // calculate rotation radian angle
      const rotationRadian = (rotation * Math.PI) / 180
      const rotateSize = (width: number, height: number, rotation: number) => {
        return {
          width:
            Math.abs(Math.cos(rotationRadian) * width) +
            Math.abs(Math.sin(rotationRadian) * height),
          height:
            Math.abs(Math.sin(rotationRadian) * width) +
            Math.abs(Math.cos(rotationRadian) * height),
        }
      }

      if (image) {
        // calculate bounding box of the rotated image
        const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
          image.width,
          image.height,
          rotation,
        )

        // set canvas size to match the bounding box
        canvas.width = bBoxWidth
        canvas.height = bBoxHeight

        // translate canvas context to a central location to allow rotating and flipping around the center
        ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
        ctx.rotate(rotationRadian)
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
        ctx.translate(-image.width / 2, -image.height / 2)

        // Transparent background
        ctx.fillStyle = 'rgba(255, 255, 255, 1)'
        ctx.fillRect(0, 0, canvas.width, canvas.height)

        // draw rotated image
        ctx.drawImage(image, 0, 0)
      }

      // croppedAreaPixels values are bounding box relative
      // extract the cropped image using these values
      const data = ctx.getImageData(
        croppedPixels.x,
        croppedPixels.y,
        croppedPixels.width,
        croppedPixels.height,
      )

      // set canvas width to final desired crop size - this will clear existing context
      canvas.width = croppedPixels.width
      canvas.height = croppedPixels.height

      // paste generated rotate image at the top left corner
      ctx.putImageData(data, 0, 0)

      // As a blob
      const blob: Promise<Blob | null> = new Promise((resolve) => {
        canvas?.toBlob((blob) => {
          resolve(blob)
        }, 'image/jpeg')
      })

      return blob
    }

    const cropImage = async () => {
      try {
        const blob: Blob | null = await getCroppedImageBlob()
        const file: File | null = blob && blobToFile(blob)
        if (file) {
          props.onCrop(file)
        }
      } catch (err) {
        handleRejection()
      }
    }

    return (
      <section className={'ImageCroppingTool isActive'} data-testid="image-cropping-tool">
        <div className="cropping-tools-container">
          <div className="cropper-container">
            <Cropper
              image={getBlobURL}
              crop={crop}
              zoom={zoom}
              rotation={rotation}
              aspect={3 / 3}
              onCropChange={onCropChange}
              onCropComplete={onCropComplete}
              onZoomChange={onZoomChange}
              onRotationChange={onRotationChange}
            />
          </div>
          <CButton
            className="cropper-tools-btn"
            data-testid="confirm-image-crop"
            onClick={async () => await cropImage()}
          >
            <i className={'fas fa-check-circle'} />
            Crop
          </CButton>
        </div>
      </section>
    )
  }),
)
