import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { AccountStore, ApplicationStore, AuthStore, NotificationStore } from '../stores'
import { TermsOrPrivacyResponse } from '../stores/AccountStore'
import { CCard, CCardBody, CCol, CContainer, CRow } from '@coreui/react'
import ReactHtmlParser from 'react-html-parser'
import { parseDate } from '../lib/time'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { leftArrow } from '../components/icons/leftArrow'

type TProps = {
  authStore?: AuthStore
  accountStore?: AccountStore
  notificationStore?: NotificationStore
}
export const AntiSpamPolicy = inject(
  ApplicationStore.names.authStore,
  ApplicationStore.names.accountStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [data, setData] = useState<TermsOrPrivacyResponse | undefined>(undefined)

    useEffect(() => {
      props
        .accountStore!.getLatestAntiSpamPolicy()
        .then((data) => setData(data))
        .catch(() => {
          props.notificationStore?.setNotificationMessage(
            'Failed to load policy. Please try again.',
            'danger',
            3000,
          )
        })
    }, [])

    const renderData = (data: TermsOrPrivacyResponse) => {
      return (
        <>
          <CRow>
            <CCol>
              <h1 className="text-center">{data.title}</h1>
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CCol>
              <div className="text-center subtle">
                <strong>Effective Date: {parseDate(data.publishDate)}</strong>
              </div>
            </CCol>
          </CRow>

          <CRow className="mb-3">
            <CCol>
              <div className={'content-container-wrapper p-3'}>{ReactHtmlParser(data.content)}</div>
            </CCol>
          </CRow>
        </>
      )
    }

    return (
      <section
        className={classnames('PolicyPage', {
          'bg-light min-vh-100 d-flex flex-row align-items-center justify-content-center':
            !props.authStore?.isAuthenticated(),
        })}
      >
        <CCard>
          <CCardBody>
            <CContainer>
              {!props.authStore?.isAuthenticated() && (
                <Link
                  className="nav-back clickable"
                  data-testid="nav-back"
                  aria-label="navigate back to previous page"
                  tabIndex={0}
                  to="/"
                >
                  {leftArrow()}
                </Link>
              )}
              {data && renderData(data)}
            </CContainer>
          </CCardBody>
        </CCard>
      </section>
    )
  }),
)
