import React, { InputHTMLAttributes } from 'react'
import { CFormFeedback, CFormSelect } from '@coreui/react'
import classNames from 'classnames'
import { useValidation } from '../../lib/hooks'
import { CFormSelectProps } from '@coreui/react/dist/components/form/CFormSelect'

export type ValidatedSelectProps<P> = Omit<P, 'onChange' | 'required' | 'size'> & {
  value?: string
  required?: boolean
  validate: (v: string) => string | undefined
  onChange: (v: string | undefined) => any
  renderInvalidMessage?: (invalidReason: string | undefined) => string | undefined
}

/**
 * Helper wrapper component around CoreUI CFormInput to consistently apply more modern reactive validation
 * @param props
 * @constructor
 */
export function ValidatedCFormSelect(
  props: ValidatedSelectProps<CFormSelectProps & InputHTMLAttributes<HTMLSelectElement>>,
) {
  const { value, validate, renderInvalidMessage, onChange, required, ...rest } = props

  const {
    value: _value,
    setValue,
    isValid,
    invalidReason,
    showInvalidReason,
    invokeValidation,
    invalidMessage,
  } = useValidation({
    value: value || '',
    validate,
    required,
    onChange,
    renderInvalidMessage,
  })

  return (
    <section className={'ValidatedInput'}>
      {props.required && (
        <i
          data-testid="test-required-icon"
          className={classNames('fas fa-asterisk required-icon', {
            invalid: showInvalidReason && invalidReason,
            valid: isValid,
          })}
        />
      )}
      <CFormSelect
        {...rest}
        value={_value}
        onChange={({ target: { value: v } }) => setValue(v)}
        valid={isValid}
        invalid={showInvalidReason && !!invalidReason}
        onBlur={() => invokeValidation()}
        required={required}
      >
        {rest.children}
      </CFormSelect>

      {showInvalidReason && (
        <CFormFeedback invalid>{invalidReason !== 'required' && invalidMessage}</CFormFeedback>
      )}
    </section>
  )
}
