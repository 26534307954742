import { CButton, CCol, CFormInput, CLink } from '@coreui/react'
import { inject, observer } from 'mobx-react'
import React, { SyntheticEvent, useState } from 'react'
import { ApplicationStore, NotificationStore, UserStore } from '../stores'
import MobilizeVISIONLogo from '../assets/brand/VC_Logo_Full Color_Vertical.png'
import { useHistory } from 'react-router-dom'

type TProps = {
  applicationStore?: ApplicationStore
  userStore?: UserStore
  notificationStore?: NotificationStore
}

export const RequestPasswordReset = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [email, setEmail] = useState<string>('')
    const mobilizeVisionLogo = MobilizeVISIONLogo
    const history = useHistory()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const requestReset = (e: SyntheticEvent) => {
      setIsSubmitting(true)
      e.preventDefault()

      props.userStore
        ?.requestPasswordReset(email)
        .then((res) => {
          props.notificationStore?.setNotificationMessage(
            'If the email provided is in our records, an email will be sent with instructions to reset your password',
            'info',
            5000,
          )
          history.push('/signin')
        })
        .catch((err) => {
          props.notificationStore?.setNotificationMessage(
            'Something went wrong. Please try again.',
            'danger',
            5000,
          )
          setIsSubmitting(false)
        })
    }

    return (
      <section className="RequestReset">
        <div className="sign-in-modal">
          <img src={mobilizeVisionLogo} className="MobilizeVisionLogo" alt="Mobilize Vision Logo" />
          <h5>Forgot your password?</h5>
          <p>Fill in your email and we will send you password reset instructions.</p>
          <form data-testid="request-reset-password-form" onSubmit={(e) => requestReset(e)}>
            <CFormInput
              type="email"
              id="email-input"
              data-testid="email-input"
              placeholder="Email"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              value={email}
            />
            <CButton
              type="submit"
              disabled={email === '' || isSubmitting}
              className="btn"
              data-testid="btn-request-password-reset"
            >
              Request Password Reset
            </CButton>
          </form>
          <CCol className="link-group">
            <CLink className="clickable" href="/signup">
              Sign up
            </CLink>
            <CLink className="clickable" href="/signin">
              Sign in
            </CLink>
          </CCol>
        </div>
      </section>
    )
  }),
)
