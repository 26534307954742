import React from 'react'
export type TProps = {
  className?: string
}
export const Spinner = (props: TProps) => {
  // Placeholder until we want our own custom
  // sourced from https://icons8.com/preloaders/en/search/spinner
  // Used a gif as opposed to css animations anticipating possible IE limitations
  return (
    <img
      data-testid="spinner"
      className={props.className}
      src={'/spinner.gif'}
      alt={'Loading Screen'}
    />
  )
}
