import React from 'react'
import classnames from 'classnames'
import { AutoCompleteItem } from './ServerBackedMultiSelectAutoComplete'

interface TProps<T extends AutoCompleteItem> {
  selectedItems: Array<T>
  onRemove: (id: number) => void
  onUndestroy?: (id: number) => void
}

export const CustomSelectedItems = <T extends AutoCompleteItem>(props: TProps<T>) => {
  const makeSelectionDisplayValue = (item: AutoCompleteItem) => {
    return item.shortLabel ? `${item.shortLabel} - ${item.label}` : item.label
  }

  return (
    <section className="CustomSelectedItems">
      {props.selectedItems.map((item: AutoCompleteItem) => {
        return (
          item && (
            <div
              key={item.id}
              data-testid={`custom-selected-item-${item.id}`}
              className={classnames('selected-item', {
                destroy: item.destroy,
                destroyable: !item.destroy && item.destroyable,
                staged: !item.destroy && !item.destroyable,
              })}
            >
              <span>{makeSelectionDisplayValue(item)}</span>
              {item.destroy ? (
                <i
                  className="fas fa-plus"
                  tabIndex={0}
                  aria-label="Re-add item that is marked for removal"
                  data-testid={`undestroy-item-${item.id}`}
                  onClick={() => props.onUndestroy && props.onUndestroy(item.id)}
                  onKeyDown={({ key: kypress }) => {
                    if (kypress === 'Enter') {
                      props.onUndestroy && props.onUndestroy(item.id)
                    }
                  }}
                />
              ) : (
                <i
                  className="fas fa-times"
                  tabIndex={0}
                  data-testid={`remove-item-${item.id}`}
                  aria-label="Remove item"
                  onClick={() => props.onRemove(item.id)}
                  onKeyDown={({ key: kypress }) => {
                    if (kypress === 'Enter') {
                      props.onRemove(item.id)
                    }
                  }}
                />
              )}
            </div>
          )
        )
      })}
    </section>
  )
}
