import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, NotificationStore } from './stores'
import './scss/style.scss'
import { AuthBoundary } from './components/boundary'
import { Alert } from './components/custom'

type TProps = {
  applicationStore?: ApplicationStore
  notificationStore?: NotificationStore
}

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

export const App = inject(
  'applicationStore',
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    useEffect(() => {
      props.applicationStore
        ?.getSiteSettings()
        .then((data) => props.applicationStore?.setSiteSettings(data))
        .catch(() => {
          props.notificationStore?.setNotificationMessage(
            'Failed to initialize application settings. Please refresh the page',
            'danger',
            3000,
            true,
          )
        })
    }, [])

    return (
      <section className="App">
        <Switch>
          <React.Suspense fallback={loading}>
            <Switch>
              {props.applicationStore?.siteSettings && (
                <AuthBoundary>
                  <Route path="/" render={(props) => <DefaultLayout {...(props as any)} />} />
                </AuthBoundary>
              )}
            </Switch>
            {props.notificationStore?.message && (
              <div className="app-global-alert" data-testid="app-global-alert">
                <Alert
                  message={props.notificationStore.message}
                  color={props.notificationStore.severity}
                  onToggleAlert={() => props.notificationStore?.clearMessage()}
                />
              </div>
            )}
          </React.Suspense>
        </Switch>
      </section>
    )
  }),
)
