import { PricingItemData } from '../types'
import { ListingOrganizationFormData } from '../../components/form/fragments/listing/OrganizationDetails'
import { ListingContractingDetailsFormData } from '../../components/form/fragments/listing/ContractingDetails'
import { validateLength, validateWebsite, validateYear } from './validation'

export const uuid = () => {
  let dt = new Date().getTime()
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

export const determineSocialMediaIconClassName = (name: string) => {
  switch (name.toLowerCase()) {
    case 'facebook':
      return 'fab fa-facebook'
    case 'linkedin':
      return 'fab fa-linkedin'
    case 'twitter':
      return 'fab fa-twitter-square'
    default:
      return undefined
  }
}

export const arrayContainsAny = (arr: any[], elements: any[]) => {
  return arr.some((e) => elements.includes(e))
}

export const objectIsEmpty = (obj: object) => {
  return Object.keys(obj).length === 0 && Object.values(obj).every((v) => !v)
}

export const capitalizeFirstLetter = (str: string) => {
  if (typeof str === 'string') {
    const l = str.toLowerCase()
    return l.charAt(0).toUpperCase() + l.slice(1)
  }
}

export const titleCase = (str: string) => {
  const words = str.split(' ')
  const formats = words.map((w) => {
    return capitalizeFirstLetter(w)
  })
  return formats.join(' ')
}

export const formatUrlProtocol = (url: string): string => {
  return /^(http(s?)):\/\//i.test(url) ? url : `https://${url}`
}

export const formatNumber = (num: string | number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
export const formatPricingCost = (price: PricingItemData | undefined) => {
  const currencyLookup: { [key: string]: string } = {
    usd: '$',
  }
  if (price) {
    return `${currencyLookup[price.currency]}${formatNumber((price.unitAmount / 100.0).toFixed(2))}`
  }
}

export const formatPricingPeriod = (price: PricingItemData | undefined) => {
  if (price && price.recurring) {
    const suffix =
      price.recurring.intervalCount === 1
        ? price.recurring.interval
        : `${price.recurring.intervalCount} ${price.recurring.interval}s`
    return `every ${suffix}`
  }
}

export const isListingSubmittable = (
  organizationFormData: Partial<ListingOrganizationFormData> | undefined,
  contractingFormData: Partial<ListingContractingDetailsFormData> | undefined,
): boolean => {
  if (!organizationFormData || !contractingFormData) {
    return false
  }

  let errors = [
    validateWebsite(organizationFormData.website),
    validateYear(organizationFormData.yearFounded),
    validateLength(contractingFormData.uniqueEntityId, 2),
    validateLength(contractingFormData.capabilityStatement, 50),
    validateLength(organizationFormData.name, 2),
    validateLength(organizationFormData.tagLine, 2),
    validateLength(organizationFormData.headquarters?.city, 2),
    validateLength(organizationFormData.headquarters?.addressLine1, 2),
    validateLength(organizationFormData.headquarters?.postalCode, 2),
  ]

  organizationFormData.socialMedia &&
    organizationFormData.socialMedia.forEach((socialAccount) => {
      if (socialAccount.identifier.length === 1) {
        errors.push(validateLength(socialAccount.identifier, 2))
      }
    })

  if (errors.some((err) => !!err)) {
    return false
  }

  return [
    organizationFormData.logo,
    organizationFormData.pointOfContact,
    organizationFormData.headquarters?.stateOrProvince,
    organizationFormData.headquarters?.country,
  ].every((e) => !!e)
}
