import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCol, CContainer, CRow } from '@coreui/react'
import { AccountStore, ApplicationStore, AuthStore, NotificationStore } from '../stores'
import { inject, observer } from 'mobx-react'
import { Spinner } from '../components/custom'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { useHistory } from 'react-router-dom'
type TProps = {
  accountStore?: AccountStore
  authStore?: AuthStore
  notificationStore?: NotificationStore
}

export const SetupPayment = inject(
  ApplicationStore.names.accountStore,
  ApplicationStore.names.authStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [params] = useQueryParams({
      setupSuccess: withDefault(StringParam, undefined),
    })
    const history = useHistory()

    const [isSuspending, setIsSuspending] = useState<boolean>(true)
    const [sessionUrl, setSessionUrl] = useState<string | undefined>(undefined)

    useEffect(() => {
      if (params.setupSuccess && params.setupSuccess === 'true') {
        // We've been redirected back here from stripe
        props
          .accountStore!.recordSetupPaymentSessionComplete()
          .then(() => {
            props.authStore!.generateAndSetSalt()
            history.push('/')
          })
          .catch(() => {
            setIsSuspending(false)
            props.notificationStore?.setNotificationMessage(
              'Unable to confirm payment right now, please try again later.',
              'danger',
              3000,
            )
          })
      } else {
        props
          .accountStore!.createStripeSetupSession({
            origin: window.location.origin,
            successRedirectPath: `/setup_payment`,
            cancelRedirectPath: `/setup_payment`,
          })
          .then((data) => {
            setSessionUrl(data.sessionUrl)
            setIsSuspending(false)
          })
          .catch(() => {
            setIsSuspending(false)
            props.notificationStore?.setNotificationMessage(
              'Unable to set up payment right now, please try again later.',
              'danger',
              3000,
            )
          })
      }
    }, [])

    const renderFailure = () => {
      return (
        <>
          <CRow className="mb-2 justify-content-md-center">
            <CCol>
              <h2 className="text-center">Failed to Initialize Payment Setup!</h2>
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CCol>
              <p className="text-center">Please try refreshing the page again.</p>
            </CCol>
          </CRow>
        </>
      )
    }

    const renderSuspense = () => {
      return (
        <>
          <CRow className="mb-2 justify-content-md-center">
            <CCol className="d-flex justify-content-center spinner-col">
              <Spinner />
            </CCol>
          </CRow>
        </>
      )
    }

    const renderSuccess = () => {
      return (
        <>
          <CRow className="mb-2 justify-content-md-center">
            <CCol>
              <h2 className="text-center">One last step!</h2>
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CCol>
              <p className="text-center">
                You&apos;ll need to set up your account payment information.
              </p>
              <div className="text-center">
                <b>Your card will not be charged until you create a listing.</b>
              </div>
            </CCol>
          </CRow>
          <CRow className="mb-2 justify-content-center">
            <CCol className="d-flex justify-content-center">
              <CButton href={sessionUrl}>Setup Payment</CButton>
            </CCol>
          </CRow>
        </>
      )
    }

    return (
      <section className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={9} lg={7} xl={6}>
              <CCard>
                <CCardBody>
                  {isSuspending && renderSuspense()}
                  {!isSuspending && (sessionUrl ? renderSuccess() : renderFailure())}
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </section>
    )
  }),
)

export default SetupPayment
