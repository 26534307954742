import React, { useState } from 'react'
import { ListingData, ListingStore } from '../../stores/ListingStore'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, NotificationStore } from '../../stores'
import { canListingTakePaymentAction } from '../../lib/listing/utils'
import { CButton, CTooltip } from '@coreui/react'
import { ConfirmationBoundaryModal } from '../custom'
import { PricingDataSelector } from '../pricing'
import { PricingItemData } from '../../lib/types'

type TProps = {
  listing: ListingData
  listingStore?: ListingStore
  notificationStore?: NotificationStore
}
export const ListingPaymentActionButton = inject(
  ApplicationStore.names.listingStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [isSuspended, setISuspended] = useState<boolean>(false)
    const [pricing, setPricing] = useState<PricingItemData | undefined>(undefined)
    const [isPriceSelectorModalOpen, setIsPriceSelectorModalOpen] = useState<boolean>(false)

    const onCheckoutConfirm = () => {
      setISuspended(true)
      props.listingStore
        ?.openListingCheckout(props.listing.id, pricing!.id, {
          successRedirectPath: `/listings`,
          cancelRedirectPath: `/listings`,
        })
        .then((resp) => {
          // Redirect the user to the stripe checkout session
          window.location.href = resp.sessionUrl
        })
        .catch(() => {
          setISuspended(false)
          props.notificationStore?.setNotificationMessage(
            'Failed to open checkout session. Please try again.',
            'danger',
            3000,
          )
        })
    }

    if (canListingTakePaymentAction(props.listing)) {
      return (
        <>
          <CTooltip content={'Resume checkout'}>
            <CButton
              className="listing-payment-button span-btn publish-action-btn"
              data-testid="listing-payment-button"
              onClick={() => setIsPriceSelectorModalOpen(true)}
            >
              <i className="fas fa-shopping-cart" />
            </CButton>
          </CTooltip>
          <ConfirmationBoundaryModal
            onCancel={() => setIsPriceSelectorModalOpen(false)}
            onConfirm={onCheckoutConfirm}
            isOpen={isPriceSelectorModalOpen}
            headerText={'Select a pricing option'}
            isSuspended={isSuspended}
            isConfirmDisabled={!pricing}
          >
            <section data-testid="pricing-selector-modal-content">
              <PricingDataSelector
                getPricingData={() => props.listingStore!.getListingPrices()}
                onSelect={setPricing}
                selected={pricing}
              />
            </section>
          </ConfirmationBoundaryModal>
        </>
      )
    }
    return <span />
  }),
)
