import { CCard, CCardBody, CCardTitle, CCol, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { ApplicationStore, ListingStore, NotificationStore, UserData } from '../../stores'
import { inject, observer } from 'mobx-react'
import { SocialMediaAccount } from '../../stores/UserStore'
import { determineSocialMediaIconClassName } from '../../lib/helpers/utils'

type TProps = {
  listingId: number | string
  notificationStore?: NotificationStore
  listingStore?: ListingStore
}
export const PointOfContactCard = inject(
  ApplicationStore.names.listingStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [userData, setUserData] = useState<UserData | undefined>(undefined)
    useEffect(() => {
      props.listingStore
        ?.getPointOfContact(props.listingId)
        .then((data) => {
          setUserData(data)
        })
        .catch(() => {
          props.notificationStore?.setNotificationMessage(
            'Failed to load primary contact data. Please try again.',
            'danger',
            3000,
          )
        })
    }, [props.listingId])

    const renderSocialMediaAccount = (sma: SocialMediaAccount) => {
      return (
        <CRow key={sma.id} data-testid="">
          <CCol>
            <i className={determineSocialMediaIconClassName(sma.socialMediaTypeName)} />
            <span>{sma.identifier}</span>
          </CCol>
        </CRow>
      )
    }

    const renderUserData = (data: UserData) => {
      return (
        <>
          <CRow>
            <CCol>
              <strong>
                {data.firstName} {data.lastName}
              </strong>
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol>
              <i className={'fas fa-shield-alt'} />
              <span>{data.title}</span>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <i className={'far fa-envelope'} />
              <span>{data.email}</span>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <i className={'fas fa-mobile-alt'} />
              <span>{data.cellPhone}</span>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <i className={'fas fa-phone-alt'} />
              <span>{formatWorkPhone(data)}</span>
            </CCol>
          </CRow>
          <CRow className="mt-2">{data.socialMediaAccounts.map(renderSocialMediaAccount)}</CRow>
        </>
      )
    }

    const formatWorkPhone = (data: UserData): string | undefined => {
      let result

      if (!data.workPhone) {
        result = undefined
      } else if (!data.workPhoneExt) {
        result = data.workPhone
      } else if (data.workPhoneExt.toLowerCase().includes('x')) {
        result = `${data.workPhone} ${data.workPhoneExt}`
      } else {
        result = `${data.workPhone} x${data.workPhoneExt}`
      }

      return result
    }

    return (
      <CCard className="listing-card">
        <CCardBody>
          <CCardTitle className="bold-text">Primary Contact</CCardTitle>
          {userData && renderUserData(userData)}
        </CCardBody>
      </CCard>
    )
  }),
)
