import React, { useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardImage,
  CCardSubtitle,
  CCardTitle,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
  CTooltip,
} from '@coreui/react'
import { ApplicationStore, NotificationStore, UserStore } from '../../stores'
import { inject, observer } from 'mobx-react'
import { getTimezoneOptions, parseTimezone } from '../../lib/time'
import { SocialMediaAccount } from '../../stores/UserStore'
import { ChangeEmailForm, ChangePasswordForm, EditProfileForm } from '../form'
import { determineSocialMediaIconClassName } from '../../lib/helpers/utils'

type TProps = {
  userStore?: UserStore
  notificationStore?: NotificationStore
}
export const ProfileDetailCard = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false)
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false)
    const [isChangeEmailModalOpen, setIsChangeEmailModalOpen] = useState(false)

    const wrappedIcon = (className: string | undefined) => {
      if (className) {
        return (
          <div className="icon-wrapper">
            <i className={className} />
          </div>
        )
      }
      return undefined
    }

    const renderSocialMediaAccount = (sma: SocialMediaAccount) => {
      return (
        <CCol key={sma.id}>
          {wrappedIcon(determineSocialMediaIconClassName(sma.socialMediaTypeName))}
          {sma.identifier}
        </CCol>
      )
    }

    const unconfirmedEmailMessage = (email: string) => {
      return `A confirmation email has been sent to ${email}`
    }

    const timezone =
      props.userStore?.currentUserData?.timezone &&
      parseTimezone(props.userStore.currentUserData.timezone, getTimezoneOptions('original'))?.label

    return (
      <CContainer>
        <CCard className="ProfileDetailCard">
          <CCardHeader component="h5">Profile Detail</CCardHeader>
          <CCardBody>
            <div className="card-image-container">
              <CCardImage
                orientation="top"
                {...{ src: props.userStore!.currentUserData!.headshot.path }}
              />
            </div>
          </CCardBody>
          <CCardFooter>
            <CCardTitle>
              <b>
                {props.userStore?.currentUserData?.firstName}{' '}
                {props.userStore?.currentUserData?.lastName}
              </b>
            </CCardTitle>
            <CCardSubtitle>
              {wrappedIcon('fas fa-shield-alt')}
              {props.userStore?.currentUserData?.title}
            </CCardSubtitle>

            <CRow>
              <CCol>
                {wrappedIcon('far fa-envelope')}
                {props.userStore?.currentUserData?.email}
                {props.userStore?.currentUserData?.unconfirmedEmail && (
                  <CTooltip
                    content={unconfirmedEmailMessage(
                      props.userStore?.currentUserData?.unconfirmedEmail,
                    )}
                  >
                    <span>
                      <i
                        data-testid="unconfirmed-tooltip"
                        className="unconfirmed-icon fas fa-info-circle"
                      />
                    </span>
                  </CTooltip>
                )}
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                {wrappedIcon('fas fa-mobile-alt')}
                {props.userStore?.currentUserData?.cellPhone}
              </CCol>
              <CCol>
                {wrappedIcon('fas fa-phone-alt')}
                {props.userStore?.currentUserData?.workPhone &&
                  props.userStore?.currentUserData?.workPhone}
              </CCol>
              <CCol>
                {wrappedIcon('fa fa-random')}
                {props.userStore?.currentUserData?.workPhoneExt &&
                  props.userStore?.currentUserData?.workPhoneExt}
              </CCol>
            </CRow>

            <CRow>
              <CCol>
                {wrappedIcon('far fa-clock')}
                {timezone}
              </CCol>
            </CRow>
            {props.userStore?.currentUserData &&
              props.userStore?.currentUserData?.socialMediaAccounts.length > 0 && (
                <CRow>
                  {props.userStore.currentUserData.socialMediaAccounts.map(
                    renderSocialMediaAccount,
                  )}
                </CRow>
              )}
            <CRow className="action-row">
              <CCol className="flex-column">
                <CRow className="action-subrow">
                  <CButton
                    color="success"
                    variant="outline"
                    onClick={() => setIsEditProfileModalOpen(true)}
                    data-testid="open-edit-profile-btn"
                  >
                    <i className="fas fa-pencil-alt" />
                    Edit Profile
                  </CButton>
                </CRow>
                <CRow className="action-subrow">
                  <CButton
                    color="info"
                    variant="outline"
                    onClick={() => setIsChangeEmailModalOpen(true)}
                    data-testid="open-edit-email-btn"
                  >
                    <i className="fas fa-edit" />
                    Change Email
                  </CButton>
                </CRow>
              </CCol>
              <CCol className="flex-column">
                <CRow className="action-subrow">
                  <CButton
                    color="dark"
                    variant="outline"
                    onClick={() => setIsChangePasswordModalOpen(true)}
                    data-testid="open-change-password-btn"
                  >
                    <i className="fas fa-user-secret" />
                    Change Password
                  </CButton>
                </CRow>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>

        <CModal
          size="lg"
          alignment="center"
          visible={isEditProfileModalOpen}
          onClose={() => setIsEditProfileModalOpen(false)}
        >
          <CModalHeader>
            <CModalTitle>Edit Profile</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CContainer>
              <EditProfileForm onSubmitted={() => setIsEditProfileModalOpen(false)} />
            </CContainer>
          </CModalBody>
        </CModal>

        <CModal
          size="lg"
          alignment="center"
          visible={isChangePasswordModalOpen}
          onClose={() => setIsChangePasswordModalOpen(false)}
        >
          <CModalHeader>
            <CModalTitle>Change Password</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CContainer>
              <ChangePasswordForm onSubmitted={() => setIsChangePasswordModalOpen(false)} />
            </CContainer>
          </CModalBody>
        </CModal>

        <CModal
          size="lg"
          alignment="center"
          visible={isChangeEmailModalOpen}
          onClose={() => setIsChangeEmailModalOpen(false)}
        >
          <CModalHeader>
            <CModalTitle>Change Email</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <ChangeEmailForm onSubmitted={() => setIsChangeEmailModalOpen(false)} />
          </CModalBody>
        </CModal>
      </CContainer>
    )
  }),
)
