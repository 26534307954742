import React from 'react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, ServerStore, NotificationStore } from '../../../../stores'
import {
  AutoCompleteItem,
  ServerBackedMultiSelectAutoComplete,
} from '../../../custom/ServerBackedMultiSelectAutoComplete'
import { PaginationData, SearchableParams } from '../../../../lib/types/Params'

type TProps = {
  serverStore?: ServerStore
  notificationStore?: NotificationStore
  selected: Array<ITechnologyTypes & Partial<AutoCompleteItem>>
  onChange: (nc: Array<ITechnologyTypes & Partial<AutoCompleteItem>>) => void
}

export interface ITechnologyTypes {
  id: number
  name: string
}

export const TechnologyFocusAreas = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const getTechnologyFocusAreas = (params: SearchableParams) => {
      return props.serverStore!.getTechnologyTypes(params).then((response) => ({
        data: response.data.map((r) => ({ ...r, label: r.name })),
        // This is a hack, since this isn't paginated
        pagination: {
          count: response.data.length,
          page: 1,
          pages: 1,
        } as PaginationData,
      }))
    }

    const getSelectedTechnologyFocusAreas = () => {
      return props.selected.map((t) => {
        return { ...t, label: t.name }
      })
    }

    const selectTechnologyTypes = (selected: ITechnologyTypes & AutoCompleteItem) => {
      if (selected) {
        selected.destroy = false
        props.onChange([...props.selected, { ...selected, destroy: false }])
      }
    }

    const unSelect = (tech: ITechnologyTypes & AutoCompleteItem) => {
      const item = props.selected.find((t) => t.id === tech.id)
      if (item && item.destroyable) {
        props.onChange(
          props.selected.map((t) => {
            if (t.id === tech.id) {
              return { ...t, destroy: true }
            }
            return t
          }),
        )
      } else {
        props.onChange(props.selected.filter((t) => t.id !== tech.id))
      }
    }

    const unDestroy = (id: number) => {
      props.onChange(
        props.selected.map((t) => {
          if (t.id === id) {
            return { ...t, destroy: false }
          }
          return t
        }),
      )
    }

    return (
      <section className="TechnologyFocusAreas">
        <ServerBackedMultiSelectAutoComplete
          getDataFunction={getTechnologyFocusAreas}
          onSelect={(selected) => {
            selectTechnologyTypes(selected)
          }}
          onRemove={(selected) => {
            unSelect(selected)
          }}
          onUndestroy={(selected) => {
            unDestroy(selected)
          }}
          selections={getSelectedTechnologyFocusAreas()}
          placeholder={'Search and select multiple technologies'}
          className="technology-focus-areas"
          testId={'-technology-focus-areas-select'}
        />
      </section>
    )
  }),
)
