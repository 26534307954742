import React, { SyntheticEvent, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, UserStore, NotificationStore, AuthStore } from '../stores'
import { useHistory } from 'react-router-dom'
import MobilizeVISIONLogo from '../assets/brand/VC_Logo_Full Color_Vertical.png'
import { CFormInput, CButton } from '@coreui/react'
import { LegalLinks } from '../components/custom'

type TProps = {
  applicationStore?: ApplicationStore
  userStore?: UserStore
  authStore?: AuthStore
  notificationStore?: NotificationStore
}

export const SignIn = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.authStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const history = useHistory()
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const mobilizeVisionLogo = MobilizeVISIONLogo

    const signInUser = (e: SyntheticEvent) => {
      e.preventDefault()

      return props.userStore
        ?.signInUser(email, password)
        .then(() => {
          props.notificationStore?.setNotificationMessage(
            'Successfully signed in!',
            'success',
            3000,
          )
          props.authStore?.generateAndSetSalt()
        })
        .catch((err) => {
          props.notificationStore?.setNotificationMessage(
            err.response?.data?.message || 'Failed to sign in, please try again later.',
            'danger',
            3000,
          )
        })
    }

    const goToSignup = () => {
      history.push('/signup')
    }

    return (
      <section className="SignIn">
        <div className="sign-in-modal">
          <img src={mobilizeVisionLogo} className="MobilizeVisionLogo" alt="Mobilize Vision Logo" />
          <p className="sub-title text-center">
            The directory of products, services, and companies located inside the innovation and
            improvement platform used by the Air Force and DoD teams.
            <br />
            <br />
            Learn more about features of the Mobilize VELOCITY Connector{' '}
            <a href="https://velocityconnector.com" target="_blank" rel="noreferrer">
              here
            </a>
            !
          </p>
          <p className="sub-title text-center"></p>
          <form className="sign-in-form" data-testid="sign-in-form" onSubmit={(e) => signInUser(e)}>
            <CFormInput
              type="email"
              id="email-input"
              data-testid="email-input"
              placeholder="Email"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              value={email}
            />
            <CFormInput
              type="password"
              id="password-input"
              data-testid="password-input"
              placeholder="Password"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              value={password}
            />
            <CButton
              type="submit"
              disabled={email === '' || password === ''}
              className="btn login"
              data-testid="btn-login"
            >
              Login
            </CButton>
          </form>
          <a className="clickable" href="/request_password_reset" data-testid={'request-reset-btn'}>
            Forgot password?
          </a>
          <p className="acc">Join the directory.</p>
          <CButton className="btn create" onClick={goToSignup} data-testid={'create-account-btn'}>
            Create an account
          </CButton>
          <LegalLinks />
        </div>
      </section>
    )
  }),
)
