import React from 'react'
import { CCol, CContainer, CRow } from '@coreui/react'
import { ProfileDetailCard, AccountDetailCard } from '../../components/user'

const Account = () => (
  <section className="Account">
    <CContainer>
      <CRow>
        <CCol>
          <ProfileDetailCard />
        </CCol>
        <CCol>
          <AccountDetailCard />
        </CCol>
      </CRow>
    </CContainer>
  </section>
)

export default Account
