import { isTCountry, isTRegion, TCountry, TRegion } from '../../lib/geography/countryRegionData'
import React from 'react'
import { getCountryShort } from '../../lib/geography/utils'
import { ValidatedCFormSelect } from './ValidatedCFormSelect'

type TProps = {
  onSelect: (val: TRegion | undefined) => void
  country?: string | TCountry
  value?: string | TRegion
  id?: string
  required?: boolean
  'data-testid'?: string
}
export const RegionSelectInput = (props: TProps) => {
  const getCountry = () => {
    if (props.country && !props.value) {
      return isTCountry(props.country) ? props.country.short : props.country
    }
    return 'US'
  }

  return (
    <ValidatedCFormSelect
      aria-label="region select"
      id={props.id}
      onChange={(value) => {
        value === '-1'
          ? props.onSelect(undefined)
          : props.onSelect(getCountryShort(getCountry())!.regions.find((r) => r.short === value)!)
      }}
      value={isTRegion(props.value) ? props.value.short : props.value || ''}
      required={props.required}
      data-testid={props['data-testid'] ? props['data-testid'] : 'region-input-select'}
      validate={(v) => (v ? undefined : 'required')}
    >
      <option value="-1">Select State/Province</option>
      {getCountryShort(getCountry())!.regions.map((r) => (
        <option key={r.short} value={r.short}>
          {r.long} ({r.short})
        </option>
      ))}
    </ValidatedCFormSelect>
  )
}
