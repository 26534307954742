import React, { useState, useEffect, useRef } from 'react'
import { PaginatedSearchableParams, PaginationData, ServerPageData } from '../../lib/types/Params'
import { CustomSelectedItems, Spinner } from './'
import classnames from 'classnames'
import { useDebouncedCallback } from 'use-debounce'
import { CDropdown, CDropdownMenu, CDropdownItem } from '@coreui/react'

export interface AutoCompleteItem {
  id: number
  label: string
  shortLabel?: string
  sublabel?: string
  destroy?: boolean
  destroyable?: boolean
}
export interface ServerBackedAutoCompleteProps<R extends AutoCompleteItem> {
  getDataFunction: (params: PaginatedSearchableParams) => Promise<ServerPageData<R>>

  selections: Array<R>

  label?: string
  placeholder?: string

  onRemove?: (item: R) => void
  onSelect: (selected: R) => void
  onUndestroy?: (id: number) => void

  loadingDebounceMs?: number // test entry datapoint
  selectionDebounceMs?: number // default 500
  scrollDebounceMs?: number // default 200
  scrollLoadOffsetPx?: number // default 50
  onScrollBottom?: () => void
  testId?: string
  reloadOnOpen?: boolean
  isRequired?: boolean
  className?: string
}

export const ServerBackedMultiSelectAutoComplete = <R extends AutoCompleteItem>(
  props: ServerBackedAutoCompleteProps<R>,
) => {
  const ref: any = useRef()
  const selectedItemsRef: any = useRef()
  const itemRefs = useRef<Array<HTMLDivElement>>([])
  const inputRef = useRef<HTMLInputElement>(null)

  const [items, setItems] = useState<Array<R>>([])
  const [selections, setSelections] = useState<Array<R>>(props.selections || [])
  const [search, setSearchValue] = useState('')
  const [lastPage, setLastPage] = useState<PaginationData | undefined>(undefined)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isExtending, setIsExtending] = useState(false)
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true)
  const [loadingError, setLoadingError] = useState<any>(undefined)

  useEffect(() => {
    setSelections(props.selections)

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('focusin', handleClickOutside)
      document.addEventListener('keydown', handleEscape)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('focusin', handleClickOutside)
      document.removeEventListener('keydown', handleEscape)
    }
  }, [isOpen, props.selections])

  const handleClickOutside = (e: MouseEvent | FocusEvent) => {
    if (isOutside(e)) {
      setIsOpen(false)
    }
  }

  const isOutside = (e: MouseEvent | FocusEvent): boolean => {
    return (
      (ref.current && !ref.current.contains(e.target)) ||
      (selectedItemsRef.current && selectedItemsRef.current.contains(e.target))
    )
  }

  const handleEscape = ({ key: kypress }: KeyboardEvent) => {
    if (kypress === 'Escape') {
      setIsOpen(false)
      inputRef.current && inputRef.current.blur()
    }
  }

  const getData = (searchVal?: string) => {
    setIsLoadingData(true)

    return props
      .getDataFunction({ page: 1, perPage: 10, search: searchVal ? searchVal : undefined })
      .then((page) => {
        setItems(page.data)
        setLastPage(page.pagination)
      })
      .catch((err) => setLoadingError(err))
      .finally(() => setTimeout(() => setIsLoadingData(false), 200))
  }

  const extendData = async () => {
    try {
      setIsExtending(true)
      const { data, pagination } = await props.getDataFunction({
        page: lastPage ? lastPage.page + 1 : 1,
        perPage: 10,
        search: search ? search : undefined,
      })

      setItems([...items, ...data])

      setLastPage(pagination)
    } catch (error) {
      setItems([])
      setLoadingError(error)
    } finally {
      setIsExtending(false)
      setTimeout(() => setIsLoadingData(false), props.loadingDebounceMs || 200)
    }
  }

  const onScrollBottom = () => {
    // @ts-ignore
    if (!isExtending && lastPage && lastPage.page !== lastPage.pages) {
      extendData()
    }
  }

  const debouncedScroll = useDebouncedCallback(
    // function
    () => {
      onScrollBottom()
    },
    // delay in ms
    props.scrollDebounceMs == null ? 200 : props.scrollDebounceMs,
  )

  const onScroll = (e: any) => {
    const offset = props.scrollLoadOffsetPx || 50

    if (e.target.scrollHeight - e.target.scrollTop >= e.target.clientHeight - offset) {
      debouncedScroll()
    }
  }

  const debounceChange = useDebouncedCallback(
    // function
    (value) => {
      getData(value)
    },
    // delay in ms
    props.selectionDebounceMs == null ? 500 : props.selectionDebounceMs,
  )

  const makeItemLabel = (item: R) => {
    return item.shortLabel ? `${item.shortLabel} - ${item.label}` : item.label
  }

  const isSelectable = (item: R): boolean => {
    const preExistingItem = selections.find((selection) => selection.id === item.id)
    return !preExistingItem
  }

  const renderSelectItem = (item: R, idx: number) => {
    const isActive = props.selections.some((i) => i.id === item.id)

    return (
      <div
        className={classnames(`autoselect-option dropdown-item`, {
          active: isActive,
        })}
        data-testid={`${item.id}-testid`}
        key={item.id}
        tabIndex={-1}
        role="option"
        aria-selected={isActive}
        ref={(element) => {
          if (element) {
            itemRefs.current[idx] = element
          }
        }}
        onMouseDown={() => {
          if (isActive) {
            props.onRemove && props.onRemove(item)
          } else {
            props.onSelect && props.onSelect(item)
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && isSelectable(item)) {
            props.onSelect && props.onSelect(item)
          } else if (e.key === 'ArrowDown') {
            e.preventDefault()
            idx + 1 < itemRefs.current.length && itemRefs.current[idx + 1].focus()
          } else if (e.key === 'ArrowUp' && idx === 0) {
            e.preventDefault()
            inputRef.current && inputRef.current.focus()
          } else if (e.key === 'ArrowUp') {
            e.preventDefault()
            idx - 1 > -1 && itemRefs.current[idx - 1].focus()
          }
        }}
      >
        <p>{makeItemLabel(item)}</p>
        {item.sublabel && (
          <span className={'autoselect-option-sublabel subtle'}>{item.sublabel}</span>
        )}
      </div>
    )
  }

  const renderOptionsContent = () => {
    if (isLoadingData) {
      return (
        <div
          data-testid="dropdown-item-loader"
          className="dropdown-item no-click dropdown-item-loader d-flex align-items-center"
        >
          <Spinner className="dropdown-item-spinner" />
        </div>
      )
    } else if (search && items.length === 0) {
      return <p className="dropdown-item no-click subtle">No matches...</p>
    }

    return items.map((item, index) => renderSelectItem(item, index))
  }

  const renderOptions = () => {
    if (!isOpen) {
      return <span data-testid="mount-data-loader" />
    }

    return (
      <CDropdown>
        <CDropdownMenu
          data-testid={'dropdown-menu'}
          className={classnames(`${props.className}`, {
            active: isOpen,
          })}
          onScroll={onScroll}
        >
          {renderOptionsContent()}
          {isExtending && !isLoadingData && (
            <CDropdownItem
              data-testid={`dropdown-item-loader${props.testId || ''}`}
              className="dropdown-item no-click dropdown-item-loader d-flex align-items-center"
            >
              <Spinner className="dropdown-item-spinner" />
            </CDropdownItem>
          )}
          {!!loadingError && (
            <CDropdownItem
              data-testid={`dropdown-item-error${props.testId || ''}`}
              className="dropdown-item no-click dropdown-item-error"
            >
              <span>Error loading data...</span>
            </CDropdownItem>
          )}
        </CDropdownMenu>
      </CDropdown>
    )
  }

  return (
    <section
      className={`ServerBackedMultiSelectAutoComplete AutoComplete ${
        props.className ? props.className : ''
      }`}
      ref={ref}
      data-testid={`section${props.testId ? props.testId : '-ServerBackedMultiSelectAutoComplete'}`}
    >
      <input
        type="text"
        data-testid={`autocomplete-input${props.testId || ''}`}
        aria-required={props.isRequired}
        ref={inputRef}
        role="listbox"
        className="autoselect form-control"
        value={search || ''}
        placeholder={props.placeholder}
        onFocus={() => {
          setIsOpen(true)
          if (items.length === 0 || props.reloadOnOpen) {
            getData(search)
          }
        }}
        onKeyDown={(e) => {
          const { key: kypress } = e
          if (kypress === 'ArrowDown') {
            e.preventDefault()
            itemRefs.current.length && itemRefs.current[0].focus()
          }
        }}
        onChange={({ target: { value } }) => {
          setSearchValue(value)
          debounceChange(value)
        }}
      />
      {renderOptions()}
      {selections && (
        <div ref={selectedItemsRef}>
          <CustomSelectedItems
            selectedItems={selections.map((item) => {
              return item && { ...item, value: item.label, shortValue: item.shortLabel }
            })}
            onRemove={(id: number) => {
              const itemToRemove = selections.find((selection) => selection.id === id)

              if (itemToRemove) {
                props.onRemove && props.onRemove(itemToRemove)
              }
            }}
            onUndestroy={(id: number) => {
              props.onUndestroy!(id)
            }}
          />
        </div>
      )}
    </section>
  )
}
