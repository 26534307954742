import { useEffect, useState } from 'react'

export type WithValidatorParams<T> = {
  value?: any
  required?: boolean
  validate: (v: T) => string | undefined
  onChange: (v: T | undefined) => any
  renderInvalidMessage?: (invalidReason: string | undefined) => string | undefined
}

/**
 * helpful hook to encapsulate our input validation state functions and logic
 * @param params
 */
export const useValidation = <T>(params: WithValidatorParams<T>) => {
  const [value, _setValue] = useState<any>(params.value || '')
  const [isValid, setIsValid] = useState<boolean>(!params.validate(value))
  const [invalidReason, setInvalidReason] = useState<string | undefined>(params.validate(value))
  const [showInvalidReason, setShowInvalidReason] = useState<boolean>(false)
  const [invalidMessage, setInvalidMessage] = useState<string | undefined>(undefined)
  const validateRequired = (v: string) => {
    if (params.required && !v) {
      return 'required'
    }
  }

  // alternate heavy implementation: return value object
  useEffect(() => {
    if (params.value !== value) {
      setValue(params.value)
    }
  }, [params.value])

  // Effect to handle the change of value
  const setValue = (_value: any) => {
    _setValue(_value)
    const _invalidReason = validateRequired(_value) || params.validate(_value)
    setInvalidReason(_invalidReason)

    if (!_invalidReason) {
      setIsValid(true)
      setShowInvalidReason(true)
      setInvalidMessage(undefined)
    } else {
      setIsValid(false)
      params.renderInvalidMessage && setInvalidMessage(params.renderInvalidMessage(_invalidReason))
    }

    params.onChange(_value)
  }

  const invokeValidation = () => {
    const _invalidReason = validateRequired(value) || params.validate(value)
    setIsValid(!_invalidReason)
    setInvalidReason(_invalidReason)
    setShowInvalidReason(true)
  }

  return {
    value,
    setValue,
    isValid,
    invalidReason,
    showInvalidReason,
    invokeValidation,
    invalidMessage,
  }
}
