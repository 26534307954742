import axios from 'axios'
import { AuthStore, NotificationStore } from './'
import {
  PaginatedSearchableParams,
  ServerPageData,
  SearchableParams,
  Data,
} from '../lib/types/Params'
import {
  IBusinessClassifications,
  INaicsCodes,
  ITechnologyTypes,
} from '../components/form/fragments/listing'
import { extractRestfulError, VisionRequestInterceptedError } from '../lib/errors/utils'

export type SocialMediaTypeResponse = {
  id: number
  name: string
}

export class ServerStore {
  protected authStore: AuthStore
  protected notificationStore: NotificationStore

  constructor(authStore: AuthStore, notificationStore: NotificationStore) {
    this.authStore = authStore
    this.notificationStore = notificationStore
  }

  public server() {
    const instance = axios.create({
      headers: {
        'Cache-Control': 'no-store, no cache, must-revalidate',
        Authorization: this.authStore.getJWT() || '',
        Accept: 'application/json',
      },
    })

    instance.interceptors.response.use((response) => response, this.errorInterceptor)
    return instance
  }

  public getNaicsCodes(
    params: PaginatedSearchableParams | {} = {},
  ): Promise<ServerPageData<INaicsCodes>> {
    return this.server()
      .get('/api/v1/naics_codes', {
        params,
      })
      .then(({ data }) => data as ServerPageData<INaicsCodes>)
  }

  public getBusinessClassifications(
    params: SearchableParams,
  ): Promise<Data<IBusinessClassifications>> {
    return this.server()
      .get('/api/v1/business_classifications', {
        params,
      })
      .then((response) => response as Data<IBusinessClassifications>)
  }

  public getTechnologyTypes(params: SearchableParams): Promise<Data<ITechnologyTypes>> {
    return this.server()
      .get('/api/v1/technology_types', {
        params,
      })
      .then((response) => response as Data<ITechnologyTypes>)
  }

  public getSocialMediaTypes(): Promise<Array<SocialMediaTypeResponse>> {
    return this.server()
      .get<Array<SocialMediaTypeResponse>>('/api/v1/social_media_types')
      .then(({ data }) => data)
  }

  public errorInterceptor = async (error: any) => {
    if (error.response?.status === 401) {
      const err = extractRestfulError(error)
      if (err) {
        // Our custom error response format
        switch (err.error) {
          case 'ExpiredToken':
            this.forceLogoutWithMessage('Your session has expired, please sign in again.')
            throw new VisionRequestInterceptedError(err.error)

          case 'InvalidToken':
          case 'MissingToken':
          case 'DeviseGeneral':
            this.forceLogoutWithMessage('Please sign in.')
            throw new VisionRequestInterceptedError(err.error)

          case 'UnconfirmedEmail':
            if (this.authStore.JWT) {
              this.redirectWithMessage('/confirmation_instructions', 'Please confirm your email.', [
                '/confirm_email',
              ])
            } else if (error.config.headers['X-User-Email']) {
              let buff = Buffer.from(error.config.headers['X-User-Email'])
              let base64data = buff.toString('base64')

              this.redirectWithMessage(
                `/confirmation_instructions?key=${base64data}`,
                'Please confirm your email.',
                ['/confirm_email'],
              )
            } else {
              this.redirectWithMessage('/confirmation_instructions', 'Please confirm your email.', [
                '/confirm_email',
              ])
            }
            throw new VisionRequestInterceptedError(err.error)

          case 'MissingTermsPrivacy':
            this.redirectWithMessage('/sign_terms', undefined)
            throw new VisionRequestInterceptedError(err.error)

          case 'MissingValidPayment':
            this.redirectWithMessage('/setup_payment', undefined)
            throw new VisionRequestInterceptedError(err.error)

          default:
            break
        }
      }
    }
    throw error
  }

  private forceLogoutWithMessage = (message: string) => {
    this.authStore.setJWT(null)
    this.notificationStore.setNotificationMessage(message, 'info', 3000, true)
  }

  private redirectWithMessage = (
    path: string,
    message: string | undefined,
    unlessPaths?: string[],
  ) => {
    if (
      window.location.pathname !== path &&
      (unlessPaths ? unlessPaths?.every((p) => p !== window.location.pathname) : true)
    ) {
      window.location.replace(path)
      this.notificationStore.setNotificationMessage(message, 'info', 3000, true)
    }
  }
}
