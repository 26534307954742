import { makeAutoObservable } from 'mobx'

export class NotificationStore {
  public message: string | undefined
  public severity: string | undefined
  public severityOptions: {
    [success: string]: string
  }
  public isSuppressing: boolean | undefined = false

  constructor() {
    makeAutoObservable(this)
    this.message = ''
    this.severity = undefined
    this.severityOptions = {
      success: 'success',
      danger: 'danger',
      warning: 'warning',
      info: 'info',
    }
  }

  /**
   *
   * @param message
   * @param severity
   * @param timeout
   * @param suppress setting this to true means to suppress all other notifications until this one is cleared. Really only the ServerStore interceptor should need to do this
   */
  public setNotificationMessage(
    message: string | undefined,
    severity?: string | undefined,
    timeout?: number,
    suppress?: boolean,
  ) {
    if (!this.isSuppressing) {
      this.message = message
      if (severity && !!this.severityOptions[severity]) {
        this.severity = severity
      } else {
        this.severity = undefined
      }

      this.isSuppressing = suppress

      if (timeout) {
        setTimeout(() => {
          this.clearMessage()
        }, timeout)
      }
    }
  }

  /**
   * Clear any message, including a suppressive one
   */
  public clearMessage() {
    this.isSuppressing = false
    return this.setNotificationMessage(undefined, undefined)
  }
}
