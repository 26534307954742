import React from 'react'
import { ListingData } from '../../stores/ListingStore'
import { CTooltip } from '@coreui/react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { parseDate } from '../../lib/time'

type TProps = {
  listing: ListingData
}
export const ListingStatusAlert = (props: TProps) => {
  const determineAlert = () => {
    /*
      See: https://stripe.com/docs/api/subscriptions/object?lang=ruby#subscription_object-status
      STRIPE STATUSES:
      active
      past_due
      unpaid
      canceled
      incomplete
      incomplete_expired
      trialing

      Our custom:
      active_canceled
      checkout_canceled
      awaiting_checkout
      awaiting_payment_confirmation
     */
    switch (props.listing.subscription.paymentStatus) {
      case 'unpaid':
      case 'past_due':
      case 'incomplete':
      case 'incomplete_expired':
      case 'awaiting_checkout':
      case 'checkout_canceled':
        return { color: 'danger', tooltip: 'Payment action required' }
      case 'canceled':
        return {
          color: 'light',
          tooltip: `'Canceled on ${parseDate(props.listing.subscription.cancelAt!)}`,
        }
      case 'active_canceled':
        return {
          color: 'light',
          tooltip: `Canceling on ${parseDate(props.listing.subscription.cancelAt!)}`,
        }
      case 'awaiting_payment_confirmation':
        return {
          color: 'warning',
          tooltip: 'Awaiting confirmation from payment system',
        }
      default:
        return undefined
    }
  }

  const renderAlert = (data: { color: string; tooltip: string }) => {
    return (
      <CTooltip content={data.tooltip}>
        <Link to={`/listings/${props.listing.id}`}>
          <i
            data-testid="listing-status-alert"
            className={classnames('listing-status-alert fas fa-exclamation-circle', {
              [data.color]: true,
            })}
          />
        </Link>
      </CTooltip>
    )
  }
  const alertData = determineAlert()
  return alertData ? renderAlert(alertData) : <span data-testid="no-alert-placeholder" />
}
