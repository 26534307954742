import React from 'react'

export const publishIcon = () => {
  return (
    <svg
      width="18px"
      height="18px"
      version="1.1"
      viewBox="0 0 1200 1200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m787.43 74.961h-524.93c-45.227 0-112.5 29.961-112.5 112.5v825.07c0 45.227 29.961 112.5 112.5 112.5h675c45.227 0 112.5-29.961 112.5-112.5v-675c0-90.824-54.898-262.57-262.57-262.57zm183.6 225h-108.49c-30.227 0-37.051-20.625-37.5-37.5v-109.5c104.93 15.828 136.61 97.09 145.99 147zm-33.824 750.08h-299.7v-472.02l85.988 85.988c7.3125 7.3125 16.91 10.988 26.512 10.988s19.199-3.6758 26.512-10.988c14.664-14.664 14.664-38.363 0-53.023l-150-150c-14.664-14.664-38.363-14.664-53.023 0l-150 150c-14.664 14.664-14.664 38.363 0 53.023 14.664 14.664 38.363 14.664 53.023 0l85.988-85.988v472.01l-300 0.003906c-30.188 0-37.051-20.625-37.5-37.5v-825.08c0-30.188 20.625-37.051 37.5-37.5h487.54v112.5c0 45.227 29.961 112.5 112.5 112.5h112.46v637.57c0 30.113-20.512 37.016-37.801 37.504z"
        fill="#111112"
      />
    </svg>
  )
}
