import React from 'react'
type TProps = {
  src?: string
  firstName?: string
  lastName?: string
}
export const UserAvatar = (props: TProps) => {
  const initials = () => {
    let first = props.firstName?.split('')[0] || ''
    let last = props.lastName?.split('')[0] || ''
    return `${first}${last}`.toUpperCase()
  }

  if (props.src) {
    return (
      <img
        className="user-avatar"
        src={props.src}
        data-testid="user-avatar"
        alt={`${props.firstName} ${props.lastName}`}
      />
    )
  }

  return <div className="user-avatar initials">{initials()}</div>
}
