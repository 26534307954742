import React, { FormEvent, useState } from 'react'
import { CButton, CCard, CCardBody, CCol, CContainer, CForm, CRow } from '@coreui/react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, AuthStore, NotificationStore, UserStore } from '../stores'
import {
  SignupAccountData,
  SignupAccountForm,
  SignupLoginData,
  SignupLoginForm,
  SignupUserData,
  SignupUserForm,
} from '../components/form/fragments'
import { leftArrow } from '../components/icons/leftArrow'
import { Link } from 'react-router-dom'
import {
  validateLength,
  validateEmail,
  validateValueMatch,
  validatePassword,
} from '../lib/helpers/validation'
import ReCAPTCHA from 'react-google-recaptcha'

type TProps = {
  userStore?: UserStore
  notificationStore?: NotificationStore
  authStore?: AuthStore
  applicationStore?: ApplicationStore
}
export const Signup = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.authStore,
  'applicationStore',
)(
  observer((props: TProps) => {
    const [accountData, setAccountData] = useState<Partial<SignupAccountData>>({})
    const [loginData, setLoginData] = useState<Partial<SignupLoginData>>({})
    const [userData, setUserData] = useState<Partial<SignupUserData>>({})
    const [hasValidated, setHasValidated] = useState<boolean>(false)
    const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const isSubmittable = (): boolean => {
      let errors = [
        validateLength(accountData.name, 2),
        validateEmail(loginData.email),
        validatePassword(loginData.password),
        validateValueMatch(loginData.password, loginData.confirmPassword),
        validateLength(userData.firstName, 2),
        validateLength(userData.lastName, 2),
        validateLength(userData.title, 2),
        // TODO: actually validate phone
        validateLength(userData.cellPhone, 2),
      ]

      if (errors.some((err) => !!err)) {
        return false
      }

      let presence = [accountData.logo, userData.headshot, userData.timezone, recaptchaToken].every(
        (e) => !!e,
      )

      return presence
    }

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      setHasValidated(true)
      setIsSubmitting(true)

      props
        .userStore!.signUp({
          user: {
            email: loginData.email!,
            password: loginData.password!,
            confirmPassword: loginData.confirmPassword!,
            firstName: userData.firstName!,
            lastName: userData.lastName!,
            title: userData.title!,
            cellPhone: userData.cellPhone!,
            workPhone: userData.workPhone!,
            workPhoneExt: userData.workPhoneExt!,
            timezone:
              typeof userData.timezone === 'string' ? userData.timezone : userData.timezone!.value,
            headshot: userData.headshot! as File,
            socialMedia: userData.socialMedia,
          },
          account: {
            name: accountData.name!,
            logo: accountData.logo! as File,
          },
          recaptchaToken: recaptchaToken!,
        })
        .then(({ authorization, ...userdata }) => {
          // console.log(authorization)
          setIsSubmitting(false)

          props.notificationStore?.setNotificationMessage(
            'Account successfully created!',
            'success',
            3000,
            true,
          )
          // history.push('/confirmation_instructions')
          props.authStore!.setJWT(authorization)
          props.authStore!.generateAndSetSalt()
        })
        .catch(() => {
          setIsSubmitting(false)
          // TODO some handling in case it's a 400?
          props.notificationStore?.setNotificationMessage(
            'Something went wrong, please try again',
            'danger',
            3000,
          )
        })
    }
    return (
      <section className="Signup">
        <CForm
          onSubmit={onSubmit}
          validated={hasValidated}
          className="needs-validation"
          {...{ noValidate: true }}
        >
          <CContainer>
            <CCard>
              <CCardBody>
                <div className="bounded-container d-flex align-items-center">
                  <Link
                    className="nav-back clickable"
                    data-testid="nav-back"
                    aria-label="navigate back to signin"
                    tabIndex={0}
                    to="/"
                  >
                    {leftArrow()}
                  </Link>
                  <h5>Sign Up</h5>
                </div>
                <CRow>
                  <CCol>
                    <div className="bounded-container">
                      <h6>
                        <b>My Account</b>
                      </h6>
                      <SignupAccountForm
                        value={accountData}
                        onChange={(value) => setAccountData(value)}
                      />
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <div className="bounded-container">
                      <h6>
                        <b>Your Login</b>
                      </h6>
                      <SignupLoginForm
                        value={loginData}
                        onChange={(value) => setLoginData(value)}
                      />
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <div className="bounded-container">
                      <h6>
                        <b>Your User</b>
                      </h6>
                      <SignupUserForm value={userData} onChange={(value) => setUserData(value)} />
                    </div>
                  </CCol>
                </CRow>
                <CContainer className="recaptcha-container">
                  <ReCAPTCHA
                    sitekey={props.applicationStore!.siteSettings!['recaptchaSiteKey']}
                    onChange={(token) => setRecaptchaToken(token)}
                    data-testid="recaptcha-checkbox"
                  />
                </CContainer>
                <CRow className="justify-content-md-center">
                  <CCol sm={2} className="d-flex justify-content-md-center">
                    <CButton
                      type="submit"
                      data-testid="signup-button"
                      disabled={!isSubmittable() || isSubmitting}
                    >
                      {isSubmitting ? (
                        <i data-testid="suspense-spinner" className="fa fa-spinner fa-spin" />
                      ) : (
                        <i className="fas fa-save" />
                      )}
                      Sign Up
                    </CButton>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CContainer>
        </CForm>
      </section>
    )
  }),
)
