import { CButton, CCol, CForm, CFormLabel, CRow } from '@coreui/react'
import { inject, observer } from 'mobx-react'
import React, { FormEvent, useState } from 'react'
import { validateEmail } from '../../lib/helpers/validation'
import { ApplicationStore, AuthStore, NotificationStore, UserStore } from '../../stores'
import { ValidatedCFormInput } from '../custom'

type TProps = {
  userStore?: UserStore
  notificationStore?: NotificationStore
  authStore?: AuthStore
  onSubmitted?: () => void
}
export const ChangeEmailForm = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.authStore,
)(
  observer((props: TProps) => {
    const [hasValidated, setHasValidated] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [email, setEmail] = useState<string | undefined>('')

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      setHasValidated(true)
      setIsSubmitting(true)
      props.userStore
        ?.changeEmail(email!)
        .then((data) => {
          props.onSubmitted && props.onSubmitted()
          setTimeout(() => props.userStore?.setCurrentUserData(data), 500)
          props.notificationStore?.setNotificationMessage(
            `Email confirmation has been sent to ${email}`,
            'success',
            3000,
          )
          setIsSubmitting(false)
        })
        .catch((err) => {
          setIsSubmitting(false)
          props.notificationStore?.setNotificationMessage(
            'Failed to change email, please try again.',
            'danger',
            3000,
          )
        })
    }

    const resendEmail = (unconfirmedEmail: string) => {
      setIsSubmitting(true)
      props.userStore
        ?.changeEmail(unconfirmedEmail)
        .then((data) => {
          props.onSubmitted && props.onSubmitted()
          setTimeout(() => props.userStore?.setCurrentUserData(data), 500)
          props.notificationStore?.setNotificationMessage(
            `Email confirmation has been sent to ${unconfirmedEmail}`,
            'success',
            3000,
          )
          setIsSubmitting(false)
        })
        .catch((err) => {
          setIsSubmitting(false)
          props.notificationStore?.setNotificationMessage(
            'Failed to change email, please try again.',
            'danger',
            3000,
          )
        })
    }

    const cancelEmailChange = () => {
      setIsSubmitting(true)
      props.userStore
        ?.cancelEmailChange()
        .then((data) => {
          props.onSubmitted && props.onSubmitted()
          props.notificationStore?.setNotificationMessage(
            `Change email has been undone`,
            'success',
            3000,
          )
          setTimeout(() => props.userStore?.setCurrentUserData(data), 500)
          setIsSubmitting(false)
        })
        .catch(() => {
          setIsSubmitting(false)
          props.notificationStore?.setNotificationMessage(
            'Failed to undo change email. Please try again.',
            'danger',
            3000,
          )
        })
    }

    const isSubmittable = () => {
      return !validateEmail(email)
    }

    const emailForm = () => {
      return (
        <div>
          <CForm
            onSubmit={onSubmit}
            validated={hasValidated}
            className="needs-validation"
            {...{ noValidate: true }}
          >
            <CRow className="mb-3">
              <p>
                An email will be sent to the requested email. Until it is confirmed, you will use
                your current email for logging in.
              </p>
            </CRow>
            <CRow className="mb-3">
              <CFormLabel {...{ htmlFor: 'changeUserEmail' }} className="col-sm-2 col-form-label">
                Email
              </CFormLabel>

              <CCol>
                <ValidatedCFormInput
                  type="email"
                  id="changeUserEmail"
                  data-testid="changeUserEmail"
                  placeholder="Email"
                  value={email}
                  required
                  onChange={(email: string | undefined) => {
                    setEmail(email)
                  }}
                  validate={validateEmail}
                  renderInvalidMessage={() => 'Provide a valid email'}
                />
              </CCol>
            </CRow>

            <CRow className="action-row justify-content-end">
              <CCol sm={3} className="text-end">
                <CButton
                  type="submit"
                  data-testid="save-button"
                  disabled={isSubmitting || !isSubmittable()}
                >
                  {isSubmitting ? (
                    <i data-testid="suspense-spinner" className="fa fa-spinner fa-spin" />
                  ) : (
                    <i className="fas fa-save" />
                  )}
                  Change email
                </CButton>
              </CCol>
            </CRow>
          </CForm>
        </div>
      )
    }

    const unconfirmedForm = () => {
      return (
        <div>
          <CRow className="pb-3">
            <p>
              A confirmation email has been sent to{' '}
              {props.userStore?.currentUserData?.unconfirmedEmail}
            </p>
          </CRow>
          <CRow className="button-row">
            <CButton
              color="primary"
              data-testid="resend-confirmation-button"
              disabled={isSubmitting}
              onClick={() => resendEmail(props.userStore?.currentUserData?.unconfirmedEmail!)}
            >
              <i className="fas fa-check" />
              Resend Confirmation
            </CButton>
            <CButton
              onClick={cancelEmailChange}
              disabled={isSubmitting}
              color="secondary"
              data-testid="cancel-confirmation-button"
            >
              <i className="fas fa-times" />
              Undo Change Email
            </CButton>
          </CRow>
        </div>
      )
    }

    const determineContent = () => {
      if (props.userStore?.currentUserData?.unconfirmedEmail) {
        return unconfirmedForm()
      } else {
        return emailForm()
      }
    }

    return (
      <section className="ChangeEmailForm" data-testid="ChangeEmailForm">
        {determineContent()}
      </section>
    )
  }),
)
