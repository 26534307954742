import { countryRegionData, isTCountry, TCountry } from '../../lib/geography/countryRegionData'
import React from 'react'
import { ValidatedCFormSelect } from './ValidatedCFormSelect'

type TProps = {
  onSelect: (val: TCountry | undefined) => void
  value?: string | TCountry
  id?: string
  required?: boolean
  'data-testid'?: string
}
export const CountrySelectInput = (props: TProps) => {
  return (
    <ValidatedCFormSelect
      aria-label="country select"
      id={props.id}
      onChange={(value) => {
        value === '-1'
          ? props.onSelect(undefined)
          : props.onSelect(countryRegionData.find((r) => r.short === value)!)
      }}
      value={isTCountry(props.value) ? props.value.short : props.value || ''}
      required={props.required}
      data-testid={props['data-testid'] ? props['data-testid'] : 'country-input-select'}
      validate={(v) => (v ? undefined : 'required')}
    >
      <option>Select Country</option>
      {countryRegionData.map((r) => (
        <option key={r.short} value={r.short}>
          {r.long} ({r.short})
        </option>
      ))}
    </ValidatedCFormSelect>
  )
}
